import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";

import {CampaignState} from "./campaign.slice";

export const getCampaign = createAsyncThunk(
    "/campaign/get",
    async (id: string, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/${id}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAttachments = createAsyncThunk(
    "/campaign/getAttachments",
    async (_, {rejectWithValue}) => {
        try {
            return await Request.get(`attachment`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
)

export const getCampaignOne = createAsyncThunk(
    '/campaign/getOne',
    async (id: string, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/one/${id}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
)

export const getCampaigns = createAsyncThunk(
    "/campaign/getAll",
    async ({archived}: { archived: boolean }, {getState, rejectWithValue}: any) => {
        try {
            const {offset, limit} = getState().campaign as CampaignState
            return await Request.get(`campaigns?offset=${offset}&limit=${limit}&archived=${archived}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAllCampaigns = createAsyncThunk(
    "/campaign/getAllt",
    async (_, {getState, rejectWithValue}: any) => {
        try {
            return await Request.get(`campaigns/all`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createCampaign = createAsyncThunk(
    "/campaign/create",
    async ({name, type}: any, {rejectWithValue}) => {
        try {
            const response = await Request.post('campaigns', {name, type})
            toast('Successful creation', {type: 'success'})
            return response
        } catch (error: any) {
            toast('Error during creation', {type: 'error'})
            return rejectWithValue(error);
        }
    }
);

export const getListDetailsCampaign = createAsyncThunk(
    "/campaigns/lists/details",
    async ({id}: any, {rejectWithValue}) => {
        try {
            const response = await Request.get(`campaigns/lists/details/${id}`);
            return response;
        } catch (error: any) {
            return rejectWithValue({
                name: error.name,
                message: error.message,
                stack: error.stack,
            });
        }
    }
);

export const removeListContacts = createAsyncThunk(
    "/campaigns/lists/remove",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            const response = await Request.post(`campaigns/lists/remove/${id}`, { dip_carrier: payload.dip_carrier });
            toast(`Deleted contact`, { type: 'success' });
            return response;
        } catch (error: any) {
            toast('Some error', { type: 'error' });
            return rejectWithValue(error);
        }
    }
);

export const recoverListContacts = createAsyncThunk(
    "/campaigns/lists/recover",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            const response = await Request.post(`campaigns/lists/recover/${id}`, { dip_carrier: payload.dip_carrier });
            toast(`Recover contact`, { type: 'success' });
            return response;
        } catch (error: any) {
            toast('Some error', { type: 'error' });
            return rejectWithValue(error);
        }
    }
);

export const updateCampaign = createAsyncThunk(
    "/campaign/update",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            return await Request.post(`campaigns/${id}`, payload)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const selectAttachment = createAsyncThunk(
    "attachment/select",
    async ({id, payload}: {id: string, payload: {idFile: string}}, {rejectWithValue}) => {
        try {
            const select = await Request.post(`attachment/select/${id}`, payload)
            toast('Selected file', {type: 'success'})
            return select;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
)

export const uploadCampaignAttachment = createAsyncThunk(
    'campaign/uploadAttachment',
    async ({ id, formData }: { id: string; formData: FormData }, { rejectWithValue }) => {
        try {
            const upload = await Request.post(`campaigns/${id}/attachment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast('File uploaded successfully', { type: 'success' });
            return upload;

        } catch (error: any) {
            toast('Error uploading the file', { type: 'error' });
            return rejectWithValue(error);
        }
    }
);



export const removeAttachment = createAsyncThunk(
    'attachment/campaigns/removeAttachment',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            return await Request.delete(`campaigns/${id}/attachment/remove`);
        } catch (error: any) {
            toast('Error delete', { type: 'error' });
            return rejectWithValue(error);
        }
    }
);

export const deleteAttachment = createAsyncThunk(
    'attachment/deleteAttachment',
    async ({ id }: { id: string }, { rejectWithValue }) => {
        try {
            return await Request.delete(`attachment/delete/${id}`);
        } catch (error: any) {
            toast('Error delete', { type: 'error' });
            return rejectWithValue(error);
        }
    }
);


export const importCampaignContacts = createAsyncThunk(
    "/campaign/import",
    async ({ id, payload }: any, { rejectWithValue }) => {

        const toastId = toast.loading("Uploading contacts...", {
            closeOnClick: true,
            closeButton: true,
            autoClose: false
        });

        try {

            const response = await Request.post(`campaigns/import/${id}`, {
                contacts: payload.contacts,
                filter_master: payload.filter_master,
                filter_channels: payload.filter_channels,
                list_name: payload.list_name,
                lists: payload.lists,
            })

            getListDetailsCampaign({id});

            toast.update(toastId, {
                render: "Contacts have been uploaded successfully",
                type: "success",
                isLoading: false,
                autoClose: 5000
            });

            return response;
        } catch (error: any) {
            toast.update(toastId, {
                render: "An error occurred while uploading contacts",
                type: "error",
                isLoading: false,
                autoClose: 5000
            });
            return rejectWithValue(error);
        }
    }
);

export const getCampaignLists = createAsyncThunk(
    "/campaign/lists",
    async ({id}: any, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/lists/${id}`)
        } catch (error: any) {
            toast('some error', {type: 'error'})
            return rejectWithValue(error);
        }
    }
);

export const deleteCampaignLists = createAsyncThunk(
    "/campaign/lists/delete",
    async ({id}: any, {rejectWithValue}) => {
        try {
            const response = await Request.delete(`campaigns/lists/${id}`)

            toast('deleted', {type: 'success'})
            return response
        } catch (error: any) {
            toast('some error', {type: 'error'})
            return rejectWithValue(error);
        }
    }
);

export const sendCampaignSingle = createAsyncThunk(
    "/campaign/send/single",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            const response = await Request.post(`campaigns/send/${id}`, payload)

            toast('Sent', {type: 'success'})
            return response
        } catch (error: any) {
            toast(error, {type: 'error'})
            return rejectWithValue(error);
        }
    }
);

export const updateCampaignUser = createAsyncThunk(
    "/campaign/user/upd",
    async ({user_id, payload}: any, {rejectWithValue}) => {
        try {
            return await Request.post(`campaigns/user/${user_id}`, payload)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateCampaignTemplates = createAsyncThunk(
    "/campaign/templates/upd",
    async ({campaign_id, payload}: any, {rejectWithValue}) => {
        try {
            const tmp = await Request.post(`campaigns/template/${campaign_id}`, payload)
            toast('Update successfully', {type: 'success'})
            return tmp;
        } catch (error: any) {
            toast('Update error', {type: 'error'})
            return rejectWithValue(error);
        }
    }
);

export const updateCampaignTemplate = createAsyncThunk(
    "/campaign/template/upd",
    async ({campaign_id, payload}: any, {rejectWithValue}) => {
        try {
            const tmp = await Request.post(`campaigns/template/update/${campaign_id}`, payload)
            toast('Successful template selection', {type: 'success'})
            return tmp;
        } catch (error: any) {
            toast('Error template selection', {type: 'success'})
            return rejectWithValue(error);
        }
    }
);

export const updateCampaignSchedule = createAsyncThunk(
    "/campaign/schedule/upd",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            return await Request.post(`campaigns/schedule/${id}`, payload)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const pauseCampaign = createAsyncThunk(
    "/campaign/pause",
    async ({id}: any, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/pause/${id}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resendCampaign = createAsyncThunk(
    "/campaign/resend",
    async ({id}: any, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/resend/${id}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const unrepliedCampaign = createAsyncThunk(
    "/campaign/unreplied",
    async ({id}: any, {rejectWithValue}) => {
        try {
            return await Request.get(`campaigns/unreplied/${id}`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const testCampaignSms = createAsyncThunk(
    "/campaign/test/id",
    async ({id, payload}: any, {rejectWithValue}) => {
        try {
            const response = await Request.post(`campaigns/test/${id}`, payload)

            toast('Sent successfully', {type: 'success'})
            return response
        } catch (error: any) {
            toast(error, {type: 'error'})
            return rejectWithValue(error);
        }
    }
);