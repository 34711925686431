import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { IServer } from "./server.interface";
import {deleteServer, getServer, getServers, updateServer, createServer} from "./server.request";



type ServerState = {
    server: IServer | null;
    servers: IServer[];
    limit: number;
    offset: number;
    isLoading: boolean;
};

const initialState: ServerState = {
    server: null,
    servers: [],
    limit: 8,
    offset: 0,
    isLoading: false,
};


const serverSlice = createSlice({
    name: "server",
    initialState,
    reducers: {
        clearServer: (state) => {
            state.server = null
        },
        clearServers: (state) => {
            state.servers = []
            state.offset = 0
        }
    },
    extraReducers: (builder) => {
        builder
            // get
            .addMatcher(
                (action) => action.type === getServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getServer.fulfilled.type,
                (state, action: PayloadAction<{server: IServer}>) => {
                    state.isLoading = false;
                    state.server = action.payload.server
                }
            )
            .addMatcher(
                (action) => action.type === getServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get all
            .addMatcher(
                (action) => action.type === getServers.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getServers.fulfilled.type,
                (state, action: PayloadAction<{servers: IServer[]}>) => {
                    const existingServers = current(state.servers);
                    const newServers = action.payload.servers.filter(
                        newServer => !existingServers.some(server => server.id === newServer.id)
                    );
                    state.servers = [...existingServers, ...newServers];

                    const totalLength = current(state).offset + action.payload.servers.length + 1;
                    let offset = current(state).offset + action.payload.servers.length + 1;
                    if (offset > totalLength)
                        offset = totalLength;

                    state.offset = offset || current(state).limit

                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getServers.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // create
            .addMatcher(
                (action) => action.type === createServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === createServer.fulfilled.type,
                (state, action: PayloadAction<{server: IServer}>) => {
                    state.servers = [...current(state.servers), action.payload.server]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === createServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateServer.fulfilled.type,
                (state, action: PayloadAction<{server: IServer}>) => {
                    state.servers = current(state).servers.map(server => server.id === action.payload.server.id ? action.payload.server : server)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // delete
            .addMatcher(
                (action) => action.type === deleteServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteServer.fulfilled.type,
                (state, action: PayloadAction<{id: string}>) => {
                    state.servers = current(state).servers.filter(server => server.id !== action.payload.id)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
    },
});

export const { clearServer, clearServers } = serverSlice.actions;

export default serverSlice.reducer;
