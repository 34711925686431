import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import './MaintenanceBanner.scss';

const MaintenanceBanner = () => {
    return (
        <div className="maintenance-banner">
            <FontAwesomeIcon icon={faCog} className="maintenance-banner__icon" />
            <span>Technical work is underway</span>
        </div>
    );
};

export default MaintenanceBanner;