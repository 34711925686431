import {Box, Typography} from "@mui/material";

export const CardIQ = (
    {
        title,
        count,
        sideColor,
        dotColor,
        percentage,
        subTitle
    }: {
    title: string;
    count: number;
    sideColor: string;
    dotColor?: string;
    percentage?: string;
    subTitle?: string;
}) => {
    return (
        <Box
            sx={{
                position: 'relative',
                border: '1px solid #eee',
                borderRadius: 1,
                width: 180,
                p: 1,
                mr: 2,
                mb: 2,
                mt: 2,
                backgroundColor: '#fff'
            }}
        >
            {/* Левый цветной "столбец" */}
            <Box
                sx={{
                    backgroundColor: sideColor,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 6,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                }}
            />

            {/* Содержимое карточки */}
            <Box sx={{ ml: 2 /* отступ слева, чтобы освободить место под полосу */ }}>
                {/* Верхняя строка: заголовок, точка и опционально процент */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#555' }}>
                        {title}
                    </Typography>

                    {/* Круглая цветная точка, если задали dotColor */}
                    {dotColor && (
                        <Box
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                backgroundColor: dotColor,
                            }}
                        />
                    )}

                    {/* Процентное значение в уголке (опционально) */}
                    {percentage && (
                        <Typography variant="caption" sx={{ color: '#999' }}>
                            {percentage}
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >

                    <Typography
                        variant="h4"
                        sx={{ fontWeight: 'bold', color: sideColor }}
                    >
                        {count}
                    </Typography>

                    {subTitle && (
                        <Typography variant="body2" sx={{ color: '#999' }}>
                            {subTitle}
                        </Typography>
                    )}
                </Box>


            </Box>
        </Box>
    );
}