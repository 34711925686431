import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";
import {BlockedState} from "./blocked.slice";

export const getBlocked = createAsyncThunk(
    "/blocked/getAll",
    async (_, { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().blocked as BlockedState
            const response = await Request.get(`blocked?offset=${offset}&limit=${limit}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createBlocked = createAsyncThunk(
    "/blocked/create",
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await Request.post('blocked', payload)
            toast('Successful creation', { type: 'success' })
            return response
        } catch (error: any) {
            toast('Error during creation', { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const updateBlocked = createAsyncThunk(
    "/blocked/update",
    async ({ id, payload }: any, { rejectWithValue }) => {
        try {
            const response = await Request.post(`blocked/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteBlocked = createAsyncThunk(
    "/blocked/delete",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`blocked/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);