import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {IBlocked} from "./blocked.interface";
import {createBlocked, deleteBlocked, getBlocked, updateBlocked} from "./blocked.request";

export type BlockedState = {
    blocked: IBlocked[];
    limit: number;
    offset: number;
    isLoading: boolean;
};

const initialState: BlockedState = {
    blocked: [],
    limit: 8,
    offset: 0,
    isLoading: false,
};

const blockedSlice = createSlice({
    name: "blocked",
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
            // get all
            .addMatcher(
                (action) => action.type === getBlocked.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getBlocked.fulfilled.type,
                (state, action: PayloadAction<{blocked: IBlocked[]}>) => {
                    state.blocked = [...action.payload.blocked]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getBlocked.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // create
            .addMatcher(
                (action) => action.type === createBlocked.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === createBlocked.fulfilled.type,
                (state, action: PayloadAction<{blocked: IBlocked}>) => {
                    state.blocked = [...current(state.blocked), action.payload.blocked]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === createBlocked.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateBlocked.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateBlocked.fulfilled.type,
                (state, action: PayloadAction<{blocked: IBlocked}>) => {
                    state.blocked = current(state).blocked.map(blocked => blocked.number === action.payload.blocked.number ? action.payload.blocked : blocked)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateBlocked.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // delete
            .addMatcher(
                (action) => action.type === deleteBlocked.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteBlocked.fulfilled.type,
                (state, action: PayloadAction<{id: string}>) => {
                    state.blocked = current(state).blocked.filter(blocked => blocked.number !== action.payload.id)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteBlocked.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
    },
});

export const { } = blockedSlice.actions;

export default blockedSlice.reducer;
