import React, {useEffect} from "react";
import { useAuth } from "../../../services/authContext";
import DashboardAdmin from "./DashboardAdmin";
import DashboardUser from "./DashboardUser";
import {getDashboard} from "../../../redux/dashboard/dashboard.request";
import {useDispatch} from "../../../redux/store";
import {socket} from "../../../services/socket";
import {IGroupedCardStats, IGroupedTableStats} from "../../../redux/dashboard/dashboard.interface";
import {dashboardReload, statusReload} from "../../../redux/dashboard/dashboard.slice";
import {toast} from "react-toastify";
import DashboardManager from "./DashboardManager";
import DashboardTemp from "./DashboardTemp";
import {
    getAnalyzeCarriers,
    getAnalyzeListServer,
    getAnalyzeStats,
    getDevices
} from "../../../redux/analyze/analyze.request";

export const Dashboard = () => {
    const { me } = useAuth();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashboard({startDate: null, endDate: null}))
    }, []);

    useEffect(() => {

        socket.on('dashboard_finished', (data: {groupedCardStats: IGroupedCardStats[], groupedTableStats: IGroupedTableStats[]}): any => {
            dispatch(dashboardReload(data))
            dispatch(statusReload({status: false}))
            toast('Dashboard updated', {type: 'success'})
        })

        return () => { socket.off('dashboard_finished') }

    }, [dispatch])

    useEffect(() => {
        dispatch(getAnalyzeListServer({}))
        dispatch(getAnalyzeCarriers())
        dispatch(getAnalyzeStats())
        dispatch(getDevices())
    }, [dispatch]);

    return (
        <>
            {(() => {
                switch (me?.role) {
                    case 'admin':
                        return <DashboardAdmin />;
                    case 'manager':
                        return <DashboardTemp />;
                    case 'staff':
                        return <DashboardTemp />;
                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default Dashboard;
