import {
    Box, Button,
    Card,
    Dialog, DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useCallback, useState} from "react";
import {useDispatch} from "../../../redux/store";
import {sortTable} from "../../../redux/dashboard/dashboard.slice";
import {IAnalyzeListSim, IDevices} from "../../../redux/analyze/analyze.interface";
import Countdown from "./components/Countdown";

export type CardInfoDashboardProps = {
    title: string;
    value: number;
    icon: React.ReactNode;
};

export type Column = {
    header: string;
    key: string;
    sort: 'ASC' | 'DESC';
};

export type CardListInfoDashboardProps = {
    tableIndex: string;
    title: string;
    columns: Column[];
    data: any[];
    maxRowsToShow?: number;
};

interface SimStatTableProps {
    data: IAnalyzeListSim[];
    columns: string[];
    offset: number;
    limit: number;
    totalCount: number;
    onPageChange: (newOffset: number, newLimit: number) => void;
    handleDeviceChange: (event: SelectChangeEvent<string>) => void;
    devices: IDevices[];
    selectedDevice: string;
    handleButtonClick: () => void;
}

export const CardInfoDashboard = ({ title, value, icon }: CardInfoDashboardProps) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            <Box>{title}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '35px' }}>
                <Box>{icon}</Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h4"><span>+</span>{value}</Typography>
                </Box>
            </Box>
        </Card>
    );
};

export const CardListInfoDashboard = ({
                                          title,
                                          columns,
                                          tableIndex,
                                          data,
                                          maxRowsToShow = 3,
                                      }: CardListInfoDashboardProps) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSorting = (tableIndex: string, columnKey: string) => {
        dispatch(sortTable({ tableIndex, columnKey }));
    };

    const displayedData = data.slice(0, maxRowsToShow);

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    cursor: "pointer",
                }}
                onClick={handleOpen}
            >
                <Typography variant="h6">{title}</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((col, index) => (
                                <TableCell key={index}>
                                    {col.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((col, colIndex) => (
                                    <TableCell key={colIndex}>{row[col.key]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data.length > maxRowsToShow && (
                    <Typography variant="caption" align="right">
                        More {data.length - maxRowsToShow} items...
                    </Typography>
                )}
            </Card>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((col, index) => (
                                    <TableCell key={index}>
                                        {col.header}
                                        {
                                            col.sort === 'ASC' &&
                                            <FontAwesomeIcon
                                                cursor={"pointer"}
                                                onClick={() => onSorting(tableIndex, col.key)}
                                                style={{ fontSize: "15px", marginLeft: '5px', marginRight: '5px' }} size="3x"
                                                icon={"fa-solid fa-caret-up".split(' ') as any}></FontAwesomeIcon>
                                        }
                                        {
                                            col.sort === 'DESC' &&
                                            <FontAwesomeIcon
                                                cursor={"pointer"}
                                                onClick={() => onSorting(tableIndex, col.key)}
                                                style={{ fontSize: "15px", marginLeft: '5px', marginRight: '5px' }} size="3x"
                                                icon={"fa-solid fa-caret-down".split(' ') as any}></FontAwesomeIcon>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((col, colIndex) => (
                                        <TableCell key={colIndex}>{row[col.key]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};

function DateDots({ value }: { value: string }) {
    const [dot1, dot2, dot3] = value.split('-');
    return (
        <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'center' }}>
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot1,
                }}
            />
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot2,
                }}
            />
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot3,
                }}
            />
        </Box>
    );
}

function highlightText(text: string) {

    const lower = text.toLowerCase();
    let color = '';

    if (lower.includes('flagged')) {
        color = '#FB8C00';
    } else if (lower.includes('clean') || lower.includes('active')) {
        color = '#00ACC1';
    }

    return (
        <Box
            component="span"
            sx={{
                backgroundColor: '#f0f0f0',
                px: 0.5,
                borderRadius: 1,
                p: '3px',
                fontWeight: 'bold',
                color: color || 'inherit',
            }}
        >
            {text}
        </Box>
    );
}


export const SimStatTable: React.FC<SimStatTableProps> = ({
                                                              data, columns, offset, limit,
                                                              totalCount, onPageChange, devices,
                                                              handleDeviceChange, selectedDevice,
                                                              handleButtonClick
                                                          }) => {
    const reversedColumns = [...columns].reverse();

    const handleChangePage = useCallback(
        (event: unknown, newPage: number) => {
            onPageChange(newPage * limit, limit);
        },
        [limit, onPageChange]
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newLimit = parseInt(event.target.value, 10);
            onPageChange(0, newLimit);
        },
        [onPageChange]
    );

    return (
    <>
        <Box sx={{ display: 'flex'}}>
            <FormControl size="small" sx={{ width: 200 }}>
                <InputLabel id="device-select-label">Dev server</InputLabel>
                <Select
                    labelId="device-select-label"
                    id="device-select"
                    value={selectedDevice}
                    label="Select dev"
                    onChange={handleDeviceChange}
                >
                    <MenuItem value="">
                        <em>All dev</em>
                    </MenuItem>
                    {devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                            <Typography>{device.name}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                size="small"
                sx={{ marginLeft: 1 }}
                onClick={(e) => handleButtonClick()}
            >
                Throw off \ Update
            </Button>

        </Box>

        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Block Status</TableCell>
                        <TableCell>Lock Status</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Device.Port.Slot</TableCell>
                        {reversedColumns.map((date) => (
                            <TableCell key={date} align="center">
                                {date}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{highlightText(row.BlockStatus)}</TableCell>
                            <TableCell>
                                {highlightText(row.LockStatus)}
                                {row.LockStatus === "Lock" && row.BlockEnd && (
                                    <>
                                        &nbsp;–&nbsp;
                                        <Countdown targetDate={new Date(row.BlockEnd)} />
                                    </>
                                )}
                            </TableCell>
                            <TableCell>{row.PhoneNumber}</TableCell>
                            <TableCell>{row.DevicePortSlot}</TableCell>

                            {reversedColumns.map((colDate) => (
                                <TableCell key={colDate} align="center">
                                    {row.dates[colDate]
                                        ? <DateDots value={row.dates[colDate]} />
                                        : '-'
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={totalCount}
                            rowsPerPage={limit}
                            page={Math.floor(offset / limit)}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                        />
                    </TableRow>
                </TableFooter>

            </Table>
        </TableContainer>
    </>
    );
};