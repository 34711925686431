import React from 'react';
import {
    Card,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
} from '@mui/material';
import logoATT from '../../../../assets/operators/ATT.svg';
import logoTMobile from '../../../../assets/operators/TMobile.svg';
import logoVerizon from '../../../../assets/operators/Verizon.svg';

export const SIMBoardIQ = ({carriers}: any) => {



    return (
        <Card
            variant="outlined"
            sx={{
                flexGrow: 1,
                borderRadius: 2,
                overflow: 'hidden',
                margin: '0 auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Carriers
                            </Typography>
                        </TableCell>
                        {/* T-Mobile */}
                        <TableCell align="center">
                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                <img src={logoTMobile} alt="T-Mobile" width={20} height={20} />
                                <Typography variant="body1" fontWeight="medium">T-Mobile</Typography>
                            </Box>
                        </TableCell>

                        {/* AT&T */}
                        <TableCell align="center">
                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                <img src={logoATT} alt="AT&T" width={20} height={20} />
                                <Typography variant="body1" fontWeight="medium">AT&amp;T</Typography>
                            </Box>
                        </TableCell>

                        {/* Verizon */}
                        <TableCell align="center">
                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                <img src={logoVerizon} alt="Verizon" width={20} height={20} />
                                <Typography variant="body1" fontWeight="medium">Verizon</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" fontWeight="bold">
                                Totals
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body2">{carriers['T-Mobile'] || 0}</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body2">{carriers['AT&T'] || 0}</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body2">{carriers['Verizon'] || 0}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};
