import React, { useState } from 'react';
import { Box, IconButton, Paper, Button } from '@mui/material';
import { Close, Minimize, ArrowBack } from '@mui/icons-material';
import { useAuth } from '../../services/authContext';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';

interface MenuProps {
    onNavigate: (view: string) => void;
}

const Menu: React.FC<MenuProps> = ({ onNavigate }) => (
    <Box sx={{ padding: 2 }}>
        <Button onClick={() => onNavigate('logger')} variant="contained" sx={{ marginBottom: 2 }}>
            Logger
        </Button>
    </Box>
);

const LoggerPage = () => (
    <Box sx={{ backgroundColor: '#333', color: '#fff', padding: 2, flexGrow: 1, overflowY: 'auto' }}>
        <p>Console output...</p>
    </Box>
);

const AdminWidget = () => {
    const { me } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentView, setCurrentView] = useState('menu');

    if (!me || me.role !== 'admin') {
        return null;
    }

    const toggleWidget = () => {
        setIsOpen(!isOpen);
    };

    const minimizeWidget = () => {
        setIsMinimized(!isMinimized);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const navigate = (view: string) => {
        setCurrentView(view);
    };

    return (
        <>
            <Box sx={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}>
                <IconButton
                    onClick={toggleWidget}
                    color="primary"
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: 3,
                        borderRadius: '50%',
                        padding: 1
                    }}
                >
                    <FontAwesomeIcon icon={faWindowMaximize} />
                </IconButton>
            </Box>
            {isOpen && (
                <Draggable handle=".draggable-header">
                    <Paper elevation={3} sx={{
                        width: currentView === 'logger' ? 700 : isExpanded ? 800 : 300,
                        height: isMinimized ? 40 : 400,
                        position: 'fixed',
                        bottom: 80,
                        right: 16,
                        zIndex: 1000,
                        transition: 'width 0.3s, height 0.3s, opacity 0.3s',
                        animation: 'fadeIn 0.3s',
                        '@keyframes fadeIn': {
                            from: { opacity: 0 },
                            to: { opacity: 1 }
                        }
                    }}>
                        <Box
                            className="draggable-header"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 1,
                                height: 23,
                                borderBottom: '1px solid gray',
                                cursor: 'move'
                            }}
                        >
                            {currentView !== 'menu' && (
                                <IconButton onClick={() => navigate('menu')} sx={{ position: 'absolute', left: 8 }}>
                                    <ArrowBack />
                                </IconButton>
                            )}
                            <IconButton onClick={toggleWidget} sx={{ position: 'absolute', right: 8 }}>
                                <Close />
                            </IconButton>
                        </Box>
                        {!isMinimized && (
                            <Box sx={{ padding: 2, height: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column' }}>
                                {currentView === 'menu' && <Menu onNavigate={navigate} />}
                                {currentView === 'logger' && <LoggerPage />}
                            </Box>
                        )}
                    </Paper>
                </Draggable>
            )}
        </>
    );
};

export default AdminWidget;