import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Checkbox, Divider, InputLabel, Modal, Typography, TextField, FormControl, Select, SelectChangeEvent, MenuItem } from "@mui/material"
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from '../../../../redux/store'
import { addStaff, addUserServer, createUser, deleteUser, getManagerUsers, getStaff, getUser, removeUserServer, updateUser } from '../../../../redux/user/user.request'
import { useForm } from 'react-hook-form'
import { Colors } from '../../../../types'
import { getServers } from '../../../../redux/server/server.request'
import { clearGroups, getGroups } from '../../../../redux/slices/groups'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../services/authContext'
import {clearStaff, clearUser} from "../../../../redux/user/user.slice";
import {IUser} from "../../../../redux/user/user.interface";
import {clearServers} from "../../../../redux/server/server.slice";


export const ModalAddStaff = ({ user_id, close }: { user_id: string, close: () => void }) => {
    const { staff } = useSelector(store => store.user)
    const { register, getValues, handleSubmit, reset } = useForm({mode: 'onSubmit'})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getStaff(user_id))

        return () => { dispatch(clearStaff()) }
    }, [])

    const onSubmit = (data: any) => {
        if(data.staff_ids && !Array.isArray(data.staff_ids)) {
            dispatch(addStaff({ id: user_id, payload: [data.staff_ids] }))
        }

        if(data.staff_ids && Array.isArray(data.staff_ids)) {
            dispatch(addStaff({ id: user_id, payload: data.staff_ids }))
        }
        close()
    }

    return (
        <Modal
            open={!!user_id}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Staff
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>
                <Divider></Divider>

                {
                    staff.map(it => <Box
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 1, overflowY: 'auto', height: '50%' }}
                    >
                        <Typography>{it.firstname + " " + it.lastname}</Typography>
                        <Checkbox 
                            {...register('staff_ids')} 
                            value={it.id}
                            defaultChecked={!!it.manager_id}
                        ></Checkbox>
                    </Box>)
                }

                <Divider style={{ marginTop: 30 }}></Divider>
               
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleSubmit(onSubmit)} size="large" style={{ marginTop: 20 }} variant="contained">Save</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export const ModalCreateUser = ({ open, close }: { open: boolean, close: () => void }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const { me } = useAuth()

    const onCreateUser = useCallback(async () => {
        if(!firstName || !lastName || !email || !phone) { console.log('err'); return 'err'}
        const res = await dispatch(createUser({ firstname: firstName, lastname: lastName, email, phone, role, password }))
        if(res.payload) close()
    }, [firstName, lastName, email, phone, role])

    const handleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as string);
    };

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create new user
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>
                <Divider></Divider>

                <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                    <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ width: '100%' }} placeholder="First Name" id="outlined-basic" variant="outlined" />
                </InputLabel>
                <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                    <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ width: '100%' }} placeholder="Last Name" id="outlined-basic" variant="outlined" />
                </InputLabel>
                <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                    <TextField value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%' }} placeholder="Email" id="outlined-basic" variant="outlined" />
                </InputLabel>
                <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                    <TextField value={phone} onChange={(e) => setPhone(e.target.value)} style={{ width: '100%' }} placeholder="Phone" id="outlined-basic" variant="outlined" />
                </InputLabel>

                <FormControl style={{ marginTop: 10, width: '100%' }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH }} id="demo-simple-select-label">Select User Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Select user role"
                        onChange={handleChange}
                    >
                        {me?.role !== 'manager' && <MenuItem value={'manager'}>Manager</MenuItem>}
                        {me?.role !== 'admin' && (<MenuItem value={'staff'}>Staff</MenuItem>)}
                    </Select>
                </FormControl>

                <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                    <TextField value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '100%' }} placeholder="Password" id="outlined-basic" variant="outlined" />
                </InputLabel>

                <Divider style={{ marginTop: 30 }}></Divider>
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onCreateUser} size="large" style={{ marginTop: 20 }} variant="contained">Create</Button>
                </Box>
            </Box>
        </Modal>
    )
} 

export const ModalAddServer = ({ user_id, close }: { user_id: string, close: () => void }) => {
    const { user } = useSelector(store => store.user)
    const { servers } = useSelector(store => store.server)
    const { groups } = useSelector(store => store.groups)
    const [serverIds, setServerIds] = useState<any[]>([])
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getServers())
        dispatch(getGroups())
        dispatch(getUser(user_id))

        return () => { dispatch(clearServers()); dispatch(clearGroups()); dispatch(clearUser()) }
    }, [user_id])

    useEffect(() => { setServerIds([...(user?.servers || []), ...(user?.groups || []) ].map(it => it.id)); console.log(groups.filter(group => !!serverIds.includes(group.id))) }, [user])

    const handleAddServer = (id: string, type: string) => {
        dispatch(addUserServer({ id, user_id, type }))
    };
    
    const handleRemoveServer = (id: string, type: string) => {
        dispatch(removeUserServer({ id, user_id, type }))
    };

    return (
        <Modal
            open={!!user_id}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Server
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>
                <Divider></Divider>

                <Typography style={{ marginTop: 10 }}>Groups</Typography>
                <Box sx={{ marginTop: 1, display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    { groups.filter(group => !serverIds.includes(group.id)).map(group => <Box key={group.id} sx={{
                            padding: '4px',
                            backgroundColor: 'gray',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            borderRadius: '4px',
                            color: 'white'
                    }}>
                        {group.name} <FontAwesomeIcon onClick={() => handleAddServer(group.id, 'group')} color="white" style={{ cursor: 'pointer', marginLeft: 6 }} icon={"fa-solid fa-plus".split(' ') as any}></FontAwesomeIcon>
                    </Box>) }
                </Box>
                <Typography style={{ marginTop: 10 }}>Servers</Typography>
                <Box sx={{ marginTop: 1, display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    { servers.filter(server => !serverIds.includes(server.id)).map(server => <Box key={server.id} sx={{
                            padding: '4px',
                            backgroundColor: 'gray',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            borderRadius: '4px',
                            color: 'white'
                    }}>
                        {server.name} <FontAwesomeIcon onClick={() => handleAddServer(server.id, 'server')} color="white" style={{ cursor: 'pointer', marginLeft: 6 }} icon={"fa-solid fa-plus".split(' ') as any}></FontAwesomeIcon>
                    </Box>) }
                </Box>
                <Divider style={{ marginTop: 6 }}></Divider>

                <Typography style={{ marginTop: 10 }}>Assigned Groups and Servers</Typography>
                <Box sx={{ marginTop: 1, display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                    { [...(user?.servers as any || []), ...(user?.groups || []) ].map(server => <Box key={server.id} sx={{
                            padding: '4px',
                            backgroundColor: server.link ? 'green' : 'orange',
                            // backgroundColor: server.link ? '#00cc44' : 'green',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            borderRadius: '4px',
                            color: 'white'
                    }}>
                        {server.name} <FontAwesomeIcon onClick={() => handleRemoveServer(server.id, server.link ? 'server' : 'group')} color="red" style={{ cursor: 'pointer', marginLeft: 6 }} icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>
                    </Box>) }
                </Box>
            </Box>
        </Modal>
    )
} 


export const ModalDeleteUser = ({ open, close }: { open: { id: string, firstname: string, lastname: string }, close: () => void }) => {
    const { manager_users } = useSelector(store => store.user)
    const [newUser, setNewUser] = useState<string | null>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getManagerUsers({user_id: open.id}))
        setNewUser(open.id)
    }, [])

    const onSubmit = () => {
        if(!newUser) {
            toast('Select a user', { type: 'error' })
            return
        }
        dispatch(deleteUser({ user_id: open.id, assigned_id: newUser }))
        close()
    }

    return (
        <Modal
            open={!!open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Change Chat User
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>

                <Box>
                    Current: {`${ open.lastname} ${open.firstname}`}
                </Box>
                
                <Box>
                    {
                        manager_users.filter(user => user.id !== open.id).map(user => 
                            <Box key={user.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1 }}>
                                <Typography>{`${user.lastname} ${user.firstname}`}</Typography> 
                                <Button 
                                    onClick={() => setNewUser(user.id)}
                                    variant='contained'
                                    color={user.id === newUser ? 'success' : 'inherit'}
                                >{user.id === newUser ? 'Selected' : 'Select'}</Button>
                            </Box>   
                        )
                    }
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color='error' onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Delete</Button>
                </Box>                  
            </Box>
        </Modal>
    )
}

export const ModalEditUser = ({ open, close }: { open: IUser | null, close: () => void }) => {
    const { me } = useAuth()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [dailyLimit, setDailyLimit] = useState('')

    const dispatch = useDispatch()
  
    useEffect(() => {
      if(open) {
        setFirstName(open.firstname)
        setLastName(open.lastname)
        setEmail(open.email)
        setPhone(open.phone)
        setDailyLimit((open.limits && open.limits.daily_limit) ? String(open.limits.daily_limit) : '0')
      }
    }, [])
  
    const onSubmit = () => {
        if(!firstName || !lastName || !email || !phone || !dailyLimit) return
        dispatch(updateUser({ id: open?.id, payload: { firstname: firstName, lastname: lastName, email, phone, password, daily_limit: dailyLimit} }))
        close()
    }
  
    return (
  
      <Modal
            open={!!open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Edit User</Typography>
                <div style={{ cursor: 'pointer' }} onClick={close}>
                    {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                </div>
              </Box>
  
                <TextField
                    label='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{ width: '100%', marginTop: 12 }}
                    placeholder="First Name"
                    id="outlined-basic"
                    variant="outlined"
                />

                <TextField label='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ width: '100%', marginTop: 12  }} placeholder="Last Name" id="outlined-basic" variant="outlined" />
                <TextField label='Email' disabled={me?.role === 'staff'} value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', marginTop: 12 }} placeholder="Email" id="outlined-basic" variant="outlined" />
                <TextField label='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} style={{ width: '100%', marginTop: 12  }} placeholder="Phone" id="outlined-basic" variant="outlined" />
                {open?.id !== me?.id && <TextField label='Password' value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '100%', marginTop: 12  }} placeholder="Password" id="outlined-basic" variant="outlined" />}

                <TextField
                    label='Daily Limit'
                    value={dailyLimit}
                    onChange={(e) => setDailyLimit(e.target.value)}
                    style={{ width: '100%', marginTop: 12 }}
                    placeholder="1000"
                    id="outlined-basic"
                    variant="outlined"
                />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Save</Button>
              </Box>
            </Box>
        </Modal>
    )
  }
