import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";

export const getServer = createAsyncThunk(
    "/server/get",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.get(`server/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getServers = createAsyncThunk(
    "/server/getAll",
    async (_, { getState, rejectWithValue }: any) => {
        try {
            const response = await Request.get(`server`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createServer = createAsyncThunk(
    "/server/create",
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await Request.post('server', payload)
            toast('Successful creation', { type: 'success' })
            return response
        } catch (error: any) {
            toast('Error during creation', { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const updateServer = createAsyncThunk(
    "/server/update",
    async ({ id, payload }: any, { rejectWithValue }) => {
        try {
            const response = await Request.post(`server/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteServer = createAsyncThunk(
    "/server/delete",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`server/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);