
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Menu,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@mui/material"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from '../../../redux/store'
import { sendSms } from '../../../redux/slices/sms'
import { getUsers, getManagerUsers } from '../../../redux/user/user.request'
import { updateChat } from '../../../redux/chat/chat.request'
import { toast } from 'react-toastify'
import {clearUsers} from "../../../redux/user/user.slice";

interface ChangeChatUserProps {
    open: {
        id: string;
        firstname: string;
        lastname: string;
        chat_id: string;
    };
    close: () => void;
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    option: any;
    isOpen: any;
    setIsOpen: any;
    setOption: any;
}


export const SendMessageModal = ({ open, close }: { open: boolean, close: () => void }) => {
    const [numbers, setNumbers] = useState('')
    const [text, setText] = useState('')
    const dispatch = useDispatch()

    const onSubmit = () => {
        dispatch(sendSms({ numbers, text, type: 'random' }))
    }

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Send Message
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>

                <Box sx={{ marginTop: 2 }}><Typography variant="caption">Separate numbers by ;</Typography></Box>

                <TextField
                    value={numbers}
                    onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^+\d;]/g, '');
                        setNumbers(sanitizedValue);
                    }}
                    style={{ marginTop: 4 }}
                    fullWidth
                    placeholder="Numbers"
                    multiline
                    rows={4}
                />
                <TextField
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{ marginTop: 20 }}
                    fullWidth
                    placeholder="Message"
                    multiline
                    rows={4}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Send</Button>
                </Box>
            </Box>
        </Modal>
    )
}



export const ChangeChatUser: React.FC<ChangeChatUserProps> = ({ open, close, anchorEl, setAnchorEl, option, isOpen, setIsOpen, setOption }) => {

    const { manager_users } = useSelector(store => store.user);
    const [newUser, setNewUser] = useState<string | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getManagerUsers({ user_id: open.id }));
        setNewUser(open.id);
        return () => { dispatch(clearUsers()); };
    }, [dispatch, open.id]);

    const handleMenuClick = (userId: string) => {
        // console.log('handleMenuClick', userId);
        updateAndClose(userId);
    };

    const updateAndClose = (userId: string) => {
        setNewUser(userId);
        dispatch(updateChat({ id: open.chat_id, payload: { user_id: userId } }));
        close();
    };

    const handleClose = () => {
        setIsOpen(false);
        close();
    };

    return (

        <FormControl sx={{ display: isOpen ? 'block' : 'none' }}>
            <InputLabel id="select-label">Select Option</InputLabel>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: { boxShadow: 'none' }
                }}
            >
                {manager_users.map(user => (
                    <MenuItem
                        key={user.id}
                        selected={user.id === newUser}
                        onClick={() => handleMenuClick(user.id)}
                        style={{ color: user.id === newUser ? 'green' : '' }}
                    >
                        {`${user.lastname} ${user.firstname} [${user.id === newUser ? 'Selected' : 'Select'}]`}
                    </MenuItem>
                ))}
            </Menu>
        </FormControl>
    );
};

export const InfoModal = ({ open, close }: { open: boolean, close: () => void }) => {
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Change Sending Number
                    </Typography>
                </Box>

                <DialogContent>
                    <DialogContentText id="modal-modal-description">
                        The number has been changed. Please note that the history of correspondence with the client is saved for you, but your history will not be saved for the client as he will receive a message from a new phone number. Please remind the client about you in your new message.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Box>
        </Modal>
    );
};