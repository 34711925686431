import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {Simulate} from "react-dom/test-utils";
import playing = Simulate.playing;

export const getDashboard = createAsyncThunk(
    "/dashboard/stats/",
    async (payload: { startDate: string | null, endDate: string | null}, { rejectWithValue }: any) => {
        try {
            return await Request.post(`dashboard/stats`, payload)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
)

export const reloadStatsToDay = createAsyncThunk(
    "/dashboard/stats/reload",
    async (_, { rejectWithValue }: any) => {
        try {
            return await Request.get(`dashboard/stats/reload`)
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
)