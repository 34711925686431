import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.scss';
import { AppRoutes } from './routes';
import ThemeProvider from './services/theme';
import AuthProvider from './services/authContext';
import RouteTracker from "./route-tracker";
import AdminWidget from "./pages/Widgets/AdminWidget";
import MaintenanceBanner from "./components/MaintenanceBanner/MaintenanceBanner";
import {useEffect, useState} from "react";
import {socket} from "./services/socket";
import {useDispatch} from "./redux/store";
import {getDashboard} from "./redux/dashboard/dashboard.request";

function App() {//BUILD_PATH='../server/build'

    const dispatch = useDispatch()

    const [development, setDevelopment] = useState(false)
    const [widget, setWidget] = useState(false)

    useEffect(() => {

        socket.on('system_development', (data: {type: string, status: boolean}): any => {
            setDevelopment(data.status)
        })

        socket.on('system_widget', (data: {type: string, status: boolean}): any => {
            setWidget(data.status)
        })

        socket.emit('get_system_status', {})

        return () => { socket.off('system_development'); socket.off('system_widget'); socket.off('get_system_status') }

    }, [dispatch])


  return (
      <ThemeProvider>
          <div className='app'>
              {development && <MaintenanceBanner/>}
            <AuthProvider>
                <RouteTracker />
                <AppRoutes />
                {widget && <AdminWidget/>}
            </AuthProvider>
            <ToastContainer />
          </div>
      </ThemeProvider>
  );
}

export default App;
