import React, { useEffect, useState } from "react";

function getTimeLeft(targetDate: Date) {
    const difference = +targetDate - +new Date(); // разница в мс

    if (difference <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const totalSeconds = Math.floor(difference / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { days, hours, minutes, seconds };
}

type CountdownProps = {
    targetDate: Date;
};

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    const { days, hours, minutes, seconds } = timeLeft;

    return (
        <span>
      {days}d {hours}h {minutes}m {seconds}s
    </span>
    );
};

export default Countdown;
