import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Typography,
    Switch,
    FormControlLabel
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import {socket} from "../../../../services/socket";
import {useDispatch} from "../../../../redux/store";
import {adminService} from "../../../../redux/system/system.request";

export const System = () => {
    const navigate = useNavigate();

    const [isMaintenanceMode, setMaintenanceMode] = useState(false);
    const [isDebugWidget, setDebugWidget] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {

        socket.on('system_development', (data: {type: string, status: boolean}): any => {
            setMaintenanceMode(data.status)
        })

        socket.on('system_widget', (data: {type: string, status: boolean}): any => {
            setDebugWidget(data.status)
        })

        socket.emit('get_system_status', {})

        return () => { socket.off('system_development'); socket.off('system_widget'); }

    }, [dispatch])

    const handleSwitchChange = async (switchName: string, value: boolean) => {
        if (switchName === "development") {
            setMaintenanceMode(value);
            dispatch(adminService({ type: "development", status: value }));
        } else if (switchName === "widget") {
            setDebugWidget(value);
            dispatch(adminService({ type: "widget", status: value }));
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Workspace</Typography><span> /system</span>
            </Box>

            <Box sx={{ mt: 2 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isMaintenanceMode}
                            onChange={(e) => handleSwitchChange("development", e.target.checked)}
                        />
                    }
                    label="Technical operation mode"
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={isDebugWidget}
                            onChange={(e) => handleSwitchChange("widget", e.target.checked)}
                        />
                    }
                    label="Enable the widget for debugging"
                />
            </Box>

            <Box>
                <Outlet />
            </Box>
        </>
    );
};
