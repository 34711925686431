import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";
import {UserState} from "./user.slice";

export const getUser = createAsyncThunk(
    "/user/get",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.get(`users/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getUsers = createAsyncThunk(
    "/user/getAll",
    async (_, { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().user as UserState
            const response = await Request.get(`users?offset=${offset}&limit=${limit}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createUser = createAsyncThunk(
    "/user/create",
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await Request.post('users', payload)
            toast('Successful creation', { type: 'success' })
            return response
        } catch (error: any) {
            toast('Error during creation', { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const updateUser = createAsyncThunk(
    "/user/update",
    async ({ id, payload }: any, { rejectWithValue }) => {
        try {
            const response = await Request.post(`users/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getStaff = createAsyncThunk(
    "/user/staff",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.get(`users/staff/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addStaff = createAsyncThunk(
    "/user/addStaff",
    async ({ id, payload }: { id: string, payload: any }, { rejectWithValue }) => {
        try {
            const response = await Request.post(`users/staff/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addUserServer = createAsyncThunk(
    "/user/addServer",
    async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
        try {
            const response = await Request.get(`users/server/${id}/${user_id}?type=${type}`)
            return response
        } catch (error: any) {
            toast('Server can be added to only one user', { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const removeUserServer = createAsyncThunk(
    "/user/addStaff",
    async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`users/server/${id}/${user_id}?type=${type}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const removeManagerr = createAsyncThunk(
    "/user/manager",
    async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`users/manager/${id}/${user_id}?type=${type}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getManagerUsers = createAsyncThunk(
    "/user/chat/:id",
    async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
        try {
            const response = await Request.get(`users/manager/${user_id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteUser = createAsyncThunk(
    "/user/delete",
    async ({ user_id, assigned_id }: { user_id: string, assigned_id: string }, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`users/${user_id}/${assigned_id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);