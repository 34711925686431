import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {IAnalyzeListSim, ICarriers, IDevices, IStats} from "./analyze.interface";
import {
    getAnalyzeCarriers,
    getAnalyzeListServer,
    getAnalyzeReset,
    getAnalyzeStats,
    getDevices
} from "./analyze.request";

export type AnalyzeState = {
    listSim: IAnalyzeListSim[],
    columns: string[],
    carriers: ICarriers,
    stats: IStats[],
    devices: IDevices[],
    offset: number,
    limit: number,
    totalCount: number,
    isLoadAnalyze: boolean
};

const initialState: AnalyzeState = {
    listSim: [],
    columns: [],
    carriers: {},
    stats: [],
    devices: [],
    offset: 0,
    limit: 5,
    totalCount: 0,
    isLoadAnalyze: false
};

const analyzeSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        fetchListSimWithPagination: (state, action: PayloadAction<{offset: number, limit: number}>) => {
            state.offset = action.payload.offset
            state.limit = action.payload.limit
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type === getAnalyzeListServer.pending.type,
                (state) => {
                    state.isLoadAnalyze = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeListServer.fulfilled.type,
                (state, action: PayloadAction<{analyze: IAnalyzeListSim[], columns: string[], totalCount: number}>) => {
                    state.listSim = action.payload.analyze
                    state.columns = action.payload.columns
                    state.totalCount = action.payload.totalCount
                    state.isLoadAnalyze = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeListServer.rejected.type,
                (state, action: any) => {
                    state.isLoadAnalyze = false;
                }
            )

            .addMatcher(
                (action) => action.type === getAnalyzeCarriers.pending.type,
                (state) => {
                    state.isLoadAnalyze = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeCarriers.fulfilled.type,
                (state, action: PayloadAction<{carriers: ICarriers}>) => {
                    state.carriers = action.payload.carriers
                    state.isLoadAnalyze = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeCarriers.rejected.type,
                (state, action: any) => {
                    state.isLoadAnalyze = false;
                }
            )

            .addMatcher(
                (action) => action.type === getAnalyzeStats.pending.type,
                (state) => {
                    state.isLoadAnalyze = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeStats.fulfilled.type,
                (state, action: PayloadAction<{stats: IStats[]}>) => {
                    state.stats = action.payload.stats
                    state.isLoadAnalyze = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeStats.rejected.type,
                (state, action: any) => {
                    state.isLoadAnalyze = false;
                }
            )

            .addMatcher(
                (action) => action.type === getDevices.pending.type,
                (state) => {
                    state.isLoadAnalyze = true;
                }
            )
            .addMatcher(
                (action) => action.type === getDevices.fulfilled.type,
                (state, action: PayloadAction<{devices: IDevices[]}>) => {
                    state.devices = action.payload.devices
                    state.isLoadAnalyze = false;
                }
            )
            .addMatcher(
                (action) => action.type === getDevices.rejected.type,
                (state, action: any) => {
                    state.isLoadAnalyze = false;
                }
            )

            .addMatcher(
                (action) => action.type === getAnalyzeReset.pending.type,
                (state) => {
                    state.isLoadAnalyze = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeReset.fulfilled.type,
                (state, action: PayloadAction<{}>) => {
                    state.isLoadAnalyze = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAnalyzeReset.rejected.type,
                (state, action: any) => {
                    state.isLoadAnalyze = false;
                }
            )

    },
});

export const { fetchListSimWithPagination } = analyzeSlice.actions;

export default analyzeSlice.reducer;
