import {ChangeEvent, useCallback, useEffect, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import {
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Container,
    Button,
    Stack,
    Modal,
    TextField,
    Divider,
    Select,
    FormControl,
    MenuItem,
    SelectChangeEvent,
    Switch,
    Tooltip, Input
} from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { Colors } from "../../../../types";
import { useTheme } from "../../../../services/theme";
import { createServer, getServer, updateServer } from "../../../../redux/server/server.request";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const SendingServersAdd = () => {
    const { server } = useSelector(store => store.server)
    const [name, setName] = useState('');
    const [link, setLink] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [sendingLimit, setSendingLimit] = useState(0)
    const [timeBase, setTimeBase] = useState(0)
    const [timeUnit, setTimeUnit] = useState('')

    const [plain, setPlain] = useState(false)
    const [schedule, setSchedule] = useState(false)
    const [mms, setMms] = useState(false)
    const [testServer, setTestServer] = useState(false)
    const [did, setDid] = useState(false)
    const [didTest, setDidTest] = useState('msg')

    const [didBlockTime, setDidBlockTime] = useState(0)
    const [didTimeUnit, setDidTimeUnit] = useState('')

    const [didTestMsg, setDidTestMsg] = useState(0)

    const [didTestTime, setDidTestTime] = useState('')
    const [didTestTimeUnit, setDidTestTimeUnit] = useState('')

    const [didTestAnswer, setDidTestAnswer] = useState('')

    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        if(id) {
            dispatch(getServer(id))
        }
    }, [id])

    useEffect(() => {
        if(id && server) {
            setName(server.name)
            setLink(server.link)
            setUsername(server.username)
            setPassword(server.password)
            setSendingLimit(server.sending_limit)
            setTimeBase(server.time_base)
            setTimeUnit(server.time_unit)
            setPlain(server.is_plain)
            setSchedule(server.is_shedule)
            setMms(server.is_mms)
            setDid(server.is_test)
            setDid(server.is_did)
            setDidTest(server.did_test)
            setDidBlockTime(server.did_block_time)
            setDidTimeUnit(server.did_time_unit)
            setDidTestMsg(server.did_test_msg)
            setDidTestTime(server.did_test_time)
            setDidTestTimeUnit(server.did_test_time_unit)
            setDidTestAnswer(server.did_test_answer)
        }
    }, [server])
 
    const onSubmit = () => {
        const regex = /http.*?:\d*?$/g
        if(!regex.exec(link)) {
            toast('link should end with port', { type: 'error' })
            return
        }

        if(id) {
            dispatch(updateServer({ id, payload: {
                name, link, username, password, 
                sending_limit: sendingLimit, time_base: timeBase, time_unit: timeUnit,
                is_plain: plain, is_schedule: schedule, is_mms: mms, is_did: did, did_test: didTest,
                ...(did && {did_block_time: didBlockTime, did_time_unit: didTimeUnit, did_test_msg: didTestMsg,
                did_test_time: didTestTime, did_test_time_unit: didTestTimeUnit, is_test: testServer}),
                    ...(testServer && { did_test_answer: didTestAnswer })
            } }))
        } else {
            dispatch(createServer({
                name, link, username, password, 
                sending_limit: sendingLimit, time_base: timeBase, time_unit: timeUnit,
                is_plain: plain, is_schedule: schedule, is_mms: mms, is_did: did, did_test: didTest,
                ...(did && {did_block_time: didBlockTime, did_time_unit: didTimeUnit, did_test_msg: didTestMsg,
                did_test_time: didTestTime, did_test_time_unit: didTestTimeUnit, is_test: testServer}),
                ...(testServer && { did_test_answer: didTestAnswer })
            }))
        }
    }


    const onAddCustomText = (text: string) => {
        setDidTestAnswer(prev => prev + ' ' + text)
    }

    const onSwitchDid = (value: boolean) => {
        if(!testServer)
            setDid(value)
        else
            toast('The Test Server parameter is enabled', { type: 'error' })

    }

    const onSwitchTestServer = (value: boolean) => {
        if(!did)
            setTestServer(value)
        else
            toast('The DID parameter is enabled', { type: 'error' })

    }

    const onChoseDidTest = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        console.log(value)
        setDidTestTime(value);
    };

    const theme = useTheme()

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography variant="h5">Workspace</Typography><span> /sending servers/ add</span>
            </Box>
                
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Credentials</Typography>

                <TextField value={name} onChange={e => setName(e.target.value)} placeholder="Name" fullWidth></TextField>
                <TextField value={link} onChange={e => setLink(e.target.value)} placeholder="API Link" fullWidth></TextField>
                <TextField value={username} onChange={e => setUsername(e.target.value)} placeholder="User name" fullWidth></TextField>
                <TextField value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" fullWidth></TextField>
            </Box>
            
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Sending limit</Typography>
                <Typography variant="caption" marginBottom={1}>
                    The configuration setting below allows you to set a limit on sms sending speed. For example, to limit
                    sending speed to <span style={{ color: 'red' }}>500 sms every 5 minutes</span>, you can set 
                    <span style={{ color: 'red' }}> Sending limit = 500, Time value = 5, and Time unit = minute</span> accordingly.
                    Also set <span style={{ color: 'red' }}>amount</span> of <span style={{ color: 'red' }}>several messages</span> with a <span style={{ color: 'red' }}>single HTTP request</span>.
                </Typography>

                {/* <TextField type="number" value={sendingLimit} onChange={e => setSendingLimit(+e.target.value)} placeholder="Sending limit" fullWidth></TextField> */}
                <TextField type="number" value={timeBase} onChange={e => setTimeBase(+e.target.value)} placeholder="Time Base" fullWidth></TextField>
                {/* <TextField value={timeUnit} onChange={e => setTimeUnit(e.target.value)} placeholder="Time Unit" fullWidth></TextField> */}
                <FormControl style={{ marginTop: 10, width: '100%' }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH }} id="demo-simple-select-label">Select campaign type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timeUnit}
                            label="Select campaign type"
                            onChange={(e) => setTimeUnit(e.target.value)}
                        >
                            <MenuItem value={'sec'}>Second</MenuItem>
                            <MenuItem value={'min'}>Minute</MenuItem>
                            <MenuItem value={'hr'}>Hour</MenuItem>
                        </Select>
                   </FormControl>
            </Box>

            { did && <Box sx={{
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
            }}>

                <Typography variant="subtitle1" marginBottom={1}>DID Reputation</Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2
                }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '8px' }}>
                        <TextField
                            label="Number"
                            type='number'
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={didBlockTime}
                            onChange={e => setDidBlockTime(+e.target.value)}
                        />
                        <TextField
                            select
                            label="Period"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            defaultValue="hour"
                            value={didTimeUnit}
                            onChange={(e) => setDidTimeUnit(e.target.value)}
                        >
                            <MenuItem value="sec">sec</MenuItem>
                            <MenuItem value="min">min</MenuItem>
                            <MenuItem value="hour">hour</MenuItem>
                        </TextField>
                    </Box>

                    <Divider orientation="vertical" flexItem />

                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: 1, position: 'relative', padding: '8px' }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0, 0, 0, 0.1)',
                                pointerEvents: 'none',
                                zIndex: 1,
                                borderRadius: 2,
                                display: didTest === 'msg' ? 'none' : 'block',
                                // display: (disabled) => (disabled ? 'block' : 'none'),
                                backgroundImage: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0px, rgba(0, 0, 0, 0.1) 10px, transparent 10px, transparent 20px)',
                            }}
                        />
                        <TextField
                            label="Messages"
                            type='number'
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            disabled={didTest === 'period'}
                            value={didTestMsg}
                            onChange={e => setDidTestMsg(+e.target.value)}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                        <Switch
                            checked={didTest === 'msg'}
                            onChange={() => setDidTest(didTest === 'msg' ? 'period' : 'msg')}
                            sx={{
                                '& .MuiSwitch-thumb': {
                                    backgroundColor: 'gray',
                                    '&:before': {
                                        content: '"🔒"',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '12px',
                                        color: 'white',
                                    },
                                },
                                '& .MuiSwitch-track': {
                                    backgroundColor: 'lightgray',
                                    opacity: 1,
                                },
                            }}
                        />
                    </Box>

                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: 1, position: 'relative', padding: '8px' }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0, 0, 0, 0.1)',
                                pointerEvents: 'none',
                                zIndex: 1,
                                borderRadius: 2,
                                display: didTest === 'period' ? 'none' : 'block',
                                // display: (disabled) => (disabled ? 'block' : 'none'),
                                backgroundImage: 'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0px, rgba(0, 0, 0, 0.1) 10px, transparent 10px, transparent 20px)',
                            }}
                        />
                        <TextField
                            select
                            label="Period"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            defaultValue="daly"
                            disabled={didTest === 'msg'}
                            value={didTestTimeUnit}
                            onChange={(e) => setDidTestTimeUnit(e.target.value)}
                        >
                            <MenuItem value="daly">daly</MenuItem>
                            <MenuItem value="weekly">weekly</MenuItem>
                        </TextField>

                        <TextField
                            label="Time"
                            variant="outlined"
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            disabled={didTest === 'msg'}
                            value={didTestTime}
                            onChange={onChoseDidTest}
                        />
                    </Box>

                </Box>

            </Box> }


            { testServer && <Box sx={{
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
            }}>
                <Typography variant="subtitle1" marginBottom={1}>DID Reputation</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '8px' }}>
                    <TextField
                        label="Reply message"
                        multiline
                        rows={4}
                        variant="outlined"
                        sx={{ width: 400 }}
                        InputLabelProps={{ shrink: true }}
                        value={didTestAnswer}
                        onChange={e => setDidTestAnswer(e.target.value)}
                    />
                    <Button onClick={() => { onAddCustomText(`{@Pick( | | )}`) }}
                            style={{ marginTop: 10, marginRight: 6, maxWidth: '400px' }}
                            variant="outlined">PICK</Button>
                </Box>
            </Box> }

            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Available Features</Typography>

                <Box sx={{ display: 'flex' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span style={{ alignSelf: 'center' }}>Plain</span> <Switch checked={plain} onChange={e => setPlain(e.target.checked)}></Switch>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span style={{ alignSelf: 'center' }}>MMS</span> <Switch checked={mms} onChange={e => setMms(e.target.checked)}></Switch>
                    </Box>

                    <Tooltip title="This parameter includes the server in the list of sent message analytics" arrow placement="top">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <span style={{ alignSelf: 'center' }}>DID</span> <Switch
                            disabled={testServer}
                            checked={did} onChange={e => onSwitchDid(e.target.checked)}></Switch>
                        </Box>
                    </Tooltip>

                    <Tooltip title="The server will receive messages and send responses for analytics" arrow placement="top">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <span style={{ alignSelf: 'center' }}>Test Server</span> <Switch
                            disabled={did}
                            checked={testServer} onChange={e => onSwitchTestServer(e.target.checked)}></Switch>
                        </Box>
                    </Tooltip>

                </Box>

                <Button onClick={onSubmit} variant="contained" style={{ width: 100 }}>Save</Button>
            </Box>
        </div>
    )
}
