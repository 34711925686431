import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Box} from "@mui/material";

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);


const DashboardTemp: React.FC = () => {


    return (
        <Box>
            <h1>Dashboard</h1>
            <p>He'll be here soon...</p>
        </Box>
    );

};

export default DashboardTemp;
