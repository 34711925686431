// import assets from "../../assets";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, Box, Tooltip, IconButton, Avatar, Divider, Modal, InputLabel, TextField, Typography, Button } from "@mui/material";
import { ThemeSwitcher, useTheme } from "../../services/theme";
import "./index.scss";
import { Colors } from "../../types";
import { useAuth } from "../../services/authContext";
import { useNavigate } from "react-router-dom";
import viperText from "../../assets/images/ViperSMSText.jpg";
import viperLogoDark from "../../assets/images/ViperLogoDark.webp";
import viperLogoWhite from "../../assets/images/ViperLogoWhite.webp";
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import {Visibility, VisibilityOff} from "@mui/icons-material";

type Props = {
  collapsed: boolean
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header = ({ collapsed, setShowSidebar }: Props) => {
  const { me, Logout, loggedFromAdmin, ReturnToAdmin } = useAuth()
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditMe, setOpenModalEditMe] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()

  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

 const onToggleSidebar = () => {
  setShowSidebar((prev) => {
    localStorage.setItem('sidebar', String(!prev))
    return !prev
  })
  document.body.className = 'body_overflow'
 }

 const handleLogout = () => {
  Logout()
  navigate('signin')
 }

 const returnToAdmin = () => {
     ReturnToAdmin()
 }

    const [confettiActive, setConfettiActive] = useState(false);
    const { width, height } = useWindowSize();
    const orderHooker = () => {
        setConfettiActive(true);
        setTimeout(() => {
            setConfettiActive(false);
        }, 5000);
    };

  return (
      <div style={{backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE}} className="admin_header">
          <div className="admin_header_logo_wraper">
              <div
                  style={{cursor: "pointer"}}
                  onClick={onToggleSidebar}
              >
                  <FontAwesomeIcon
                      // color={theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK}
                      icon={"fa-solid fa-bars".split(" ") as any}
                  ></FontAwesomeIcon>
              </div>
              <div style={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
                  <div style={{marginRight: "5px"}}>
                      <img style={{width: '37px'}} src={(theme === 'dark') ? viperLogoWhite : viperLogoDark} alt=""/>
                  </div>
                  <div>
                      Viper SMS
                  </div>
              </div>
          </div>

          <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', marginRight: '3'}}>
              {/* <Button style={{  width: 260 }} size='small' variant='contained'>B</Button> */}
              <>
                  {(false && me?.role === 'admin') && (
                      <div style={{marginRight: '5px', marginLeft: '5px'}} onClick={orderHooker} className="back_to_admin">Order Hooker</div>
                      )
                  }

                {confettiActive && <Confetti width={width} height={height} />}
              </>
            {loggedFromAdmin !== '0' && <div onClick={returnToAdmin} className="back_to_admin">Back to Admin</div>}
        {/* <Button style={{  width: 260 }} size='small' variant='contained'>Back to Admin</Button> */}
        <ThemeSwitcher></ThemeSwitcher>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <FontAwesomeIcon
              size="lg"
              color={theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK}
              icon={"fa-solid fa-user".split(" ") as any}
          ></FontAwesomeIcon>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        style={{ width: 300 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div style={{ marginLeft: 16, marginTop: 10, height: 20, fontWeight: 'bolder', minWidth: 300 }}>
          { me?.firstname + ' ' + me?.lastname}
        </div>
        <div style={{ marginLeft: 16, marginTop: 4, marginBottom: 10 }}>{me?.role}</div>
        <Divider />
        
        <MenuItem onClick={() => setOpenModalEditMe(true)}>
          Edit Me
        </MenuItem>
        <MenuItem onClick={() => setOpenModal(true)}>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>

      <ModalContent open={openModal} close={() => setOpenModal(false)}></ModalContent>
      <ModalContentEditMe open={openModalEditMe} close={() => setOpenModalEditMe(false)}></ModalContentEditMe>
    </div>
  );
};

const ModalContent = ({ open, close }: { open: boolean, close: () => void }) => {
  const { ChangePassword } = useAuth()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false)
    const [showPasswordNew, setShowPasswordNew] = useState(false)

  const onSubmit = () => {
    if(!currentPassword || !newPassword) return
    ChangePassword({ current_password: currentPassword, new_password: newPassword })
      close();
  }

  return (

    <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box 
              sx={{ 
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  boxShadow: 24,
                  p: 4, 
              }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>Change Password</Typography>
              <div style={{ cursor: 'pointer' }} onClick={close}>
                  {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
              </div>
            </Box>

            <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                <TextField
                    type={showPasswordCurrent ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder="Current Password"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPasswordCurrent(!showPasswordCurrent)}>
                                {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
            </InputLabel>
            <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                <TextField
                    type={showPasswordNew ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder="New Password"
                    id="outlined-basic"
                    autoComplete="current-password"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPasswordNew(!showPasswordNew)}>
                                {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
            </InputLabel>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Save</Button>
            </Box>
          </Box>
      </Modal>
  )
}

const ModalContentEditMe = ({ open, close }: { open: boolean, close: () => void }) => {
  const { me, updateMe } = useAuth()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if(me) {
      setFirstName(me.firstname)
      setLastName(me.lastname)
      setEmail(me.email)
      setPhone(me.phone)
    }
  }, [])

  const onSubmit = () => {
    if(!firstName || !lastName || !email || !phone) return
    updateMe({ firstname: firstName, lastname: lastName, email, phone })
  }

  return (

    <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box 
              sx={{ 
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  boxShadow: 24,
                  p: 4, 
              }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>Edit Me</Typography>
              <div style={{ cursor: 'pointer' }} onClick={close}>
                  {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
              </div>
            </Box>

                <TextField label='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ width: '100%', marginTop: 12 }} placeholder="First Name" id="outlined-basic" variant="outlined" />
                <TextField label='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ width: '100%', marginTop: 12  }} placeholder="Last Name" id="outlined-basic" variant="outlined" />
                <TextField label='Email' disabled={me?.role === 'staff'} value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', marginTop: 12 }} placeholder="Email" id="outlined-basic" variant="outlined" />
                <TextField label='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} style={{ width: '100%', marginTop: 12  }} placeholder="Phone" id="outlined-basic" variant="outlined" />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Save</Button>
            </Box>
          </Box>
      </Modal>
  )
}
