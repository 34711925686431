import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {IChat} from "./chat.interface";
import {ISms} from "./chat.interface";

import {
    autocompleteChats,
    changeSendingNumber,
    deleteSMSExpired,
    getChat,
    getChats,
    loadMoreChats,
    sendChatSms,
    updateChat
} from "./chat.request";

export type ChatState = {
    chat: IChat | null;
    chats: IChat[];
    autocomplete: IChat[] | null;
    limit: number;
    offset: number;
    isLoading: boolean;
    isLoadChat: boolean;
    pendingStatusUpdates: {
        [messageId: string]: 'sent' | 'delivered' | 'failed';
    };
    enteredSms: {chatId: string, text: string}[];
};

const initialState: ChatState = {
    chat: null,
    chats: [],
    autocomplete: null,
    limit: 32,
    offset: 0,
    isLoading: false,
    isLoadChat: false,
    pendingStatusUpdates: {},
    enteredSms: []
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        saveSms: (state, action: PayloadAction<{ chatId: string; text: string }>) => {
            const { chatId, text } = action.payload;

            const existingSms = state.enteredSms.find((sms) => sms.chatId === chatId);

            if (existingSms) {
                existingSms.text = text;
            } else {
                state.enteredSms.push({ chatId, text });
            }
        },
        clearSmsInChat: (state, action: PayloadAction<{ chatId: string }>) => {
            const { chatId } = action.payload;

            state.enteredSms = state.enteredSms.filter((sms) => sms.chatId !== chatId);
        },
        clearChat: (state) => {
            state.chat = null
        },
        clearChats: (state) => {
            state.chats = []
            state.offset = 0
        },
        clearAutocompleteChats: (state) => {
            state.autocomplete = null
        },
        updateMessageStatus: (
            state,
            action: PayloadAction<{
                chatId: string;
                messageId: string;
                status: 'sent' | 'delivered' | 'failed';
            }>
        ) => {
            const { chatId, messageId, status } = action.payload;

            let messageFound = false;

            if (state.chat && state.chat.id === chatId) {
                if (state.chat.messages) {
                    state.chat = {
                        ...state.chat,
                        messages: state.chat.messages.map(msg => {
                            if (msg.id === messageId) {
                                messageFound = true;
                                return { ...msg, status };
                            }
                            return msg;
                        }),
                    };
                }
            }

            state.chats = state.chats.map(chat => {
                if (chat.id === chatId && chat.messages) {
                    const updatedMessages = chat.messages.map(msg => {
                        if (msg.id === messageId) {
                            messageFound = true;
                            return { ...msg, status };
                        }
                        return msg;
                    });
                    return { ...chat, messages: updatedMessages };
                }
                return chat;
            });

            if (!messageFound) {
                state.pendingStatusUpdates[messageId] = status;
            }


        },
        onNewSms: (state, action) => {
            const data: ISms = action.payload.sms
            if (!state.chat) return
            const current_chat = current(state.chat)
            if (data.chat_id === current_chat?.id) {
                const messages = current_chat.messages || []
                if (state.chat) {
                    state.chat.messages = [...messages, data]
                }
            }
        },
        onNewChat: (state, action) => {
            const new_chat: IChat = action.payload
            let current_chats = [...current(state.chats)]
            let tagged_chats = current_chats.filter(chat => chat.is_top)
            let untagged_chats = current_chats.filter(chat => !chat.is_top)
            if(new_chat.is_top) {
                const chat_index = tagged_chats.findIndex(it => it.id === new_chat.id)
                if (chat_index === -1) {
                    state.chats = [new_chat, ...tagged_chats, ...untagged_chats]
                } else {
                    tagged_chats.splice(chat_index, 1)
                    state.chats = [new_chat, ...tagged_chats, ...untagged_chats]
                }
            } else {
                const chat_index = untagged_chats.findIndex(it => it.id === new_chat.id)
                if (chat_index === -1) {
                    state.chats = [...tagged_chats, new_chat, ...untagged_chats]
                } else {
                    untagged_chats.splice(chat_index, 1)
                    state.chats = [...tagged_chats, new_chat, ...untagged_chats]
                }
            }
        },
        onOpenChat: (state, action) => {
            const chat_id: string = action.payload
            let current_chats = [...current(state.chats)]

            state.chats = current_chats.map(chat => chat.id === chat_id ? { ...chat, have_unseen_sms: false } : chat)
        }
    },
    extraReducers: (builder) => {
        builder
            // get
            .addMatcher(
                (action) => action.type === getChat.pending.type,
                (state) => {
                    state.isLoadChat = true;
                }
            )
            .addMatcher(
                (action) => action.type === getChat.fulfilled.type,
                (state, action: PayloadAction<{chat: IChat}>) => {
                    state.chat = action.payload.chat
                    state.isLoadChat = false;
                }
            )
            .addMatcher(
                (action) => action.type === getChat.rejected.type,
                (state, action: any) => {
                    state.isLoadChat = false;
                }
            )
            // get all
            .addMatcher(
                (action) => action.type === getChats.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getChats.fulfilled.type,
                (state, action: PayloadAction<{chats: IChat[]}>) => {
                    state.offset = 0
                    state.chats = [...action.payload.chats]
                    // state.offset = current(state).offset + action.payload.chats.length || current(state).limit

                    const totalLength = current(state).offset + action.payload.chats.length + 1;
                    let offset = current(state).offset + action.payload.chats.length + 1;
                    if (offset > totalLength)
                        offset = totalLength;

                    state.offset = offset || current(state).limit

                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getChats.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // load more
            .addMatcher(
                (action) => action.type === loadMoreChats.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === loadMoreChats.fulfilled.type,
                (state, action: PayloadAction<{chats: IChat[]}>) => {
                    state.chats = [...current(state.chats), ...action.payload.chats]
                    state.offset = current(state).offset + action.payload.chats.length || current(state).limit
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === loadMoreChats.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateChat.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateChat.fulfilled.type,
                (state, action: PayloadAction<{chat: IChat}>) => {
                    state.chats = current(state.chats).map(chat => chat.id === action.payload.chat.id ? action.payload.chat : chat)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateChat.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // delete sms expired
            .addMatcher(
                (action) => action.type === deleteSMSExpired.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteSMSExpired.fulfilled.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteSMSExpired.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // change sending number
            .addMatcher(
                (action) => action.type === changeSendingNumber.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === changeSendingNumber.fulfilled.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === changeSendingNumber.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // send
            .addMatcher(
                (action) => action.type === sendChatSms.pending.type,
                (state) => {
                    // state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === sendChatSms.fulfilled.type,
                (state, action: PayloadAction<{message: ISms, id: string, expired: boolean}>) => {

                    if(state.pendingStatusUpdates[action.payload.message.id]) {
                        action.payload.message.status = state.pendingStatusUpdates[action.payload.message.id];
                    }

                    let chat = current(state.chat)?.id === action.payload.id ? current(state.chat) : null
                    if (chat && state.chat) {
                        chat = { ...current(state.chat), messages: [...current(state.chat)?.messages || [], action.payload.message ] }
                        state.chat = chat
                    }
                    state.isLoading = false;

                    if(!chat) return
                    let current_chats = [...current(state.chats)]
                    let tagged_chats = current_chats.filter(chat => chat.is_top)
                    let untagged_chats = current_chats.filter(chat => !chat.is_top)
                    if(chat.is_top) {
                        const chat_index = tagged_chats.findIndex(it => it.id === chat?.id)
                        if (chat_index === -1) {
                            state.chats = [chat, ...tagged_chats, ...untagged_chats]
                        } else {
                            tagged_chats.splice(chat_index, 1)
                            state.chats = [chat, ...tagged_chats, ...untagged_chats]
                        }
                    } else {
                        const chat_index = untagged_chats.findIndex(it => it.id === chat?.id)
                        if (chat_index === -1) {
                            state.chats = [...tagged_chats, chat, ...untagged_chats]
                        } else {
                            untagged_chats.splice(chat_index, 1)
                            state.chats = [...tagged_chats, chat, ...untagged_chats]
                        }
                    }
                }
            )
            .addMatcher(
                (action) => action.type === sendChatSms.rejected.type,
                (state, action: any) => {
                    // state.isLoading = false;
                }
            )
            // autocomplete
            .addMatcher(
                (action) => action.type === autocompleteChats.pending.type,
                (state) => {
                    // state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === autocompleteChats.fulfilled.type,
                (state, action: PayloadAction<{chats: IChat[] | null}>) => {
                    state.autocomplete = action.payload.chats
                }
            )
            .addMatcher(
                (action) => action.type === autocompleteChats.rejected.type,
                (state, action: any) => {
                    // state.isLoading = false;
                }
            )
    },
});

export const { clearChat, clearChats, clearAutocompleteChats, onNewSms, onNewChat, onOpenChat, updateMessageStatus, saveSms, clearSmsInChat } = chatSlice.actions;

export default chatSlice.reducer;
