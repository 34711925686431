import { io } from 'socket.io-client'

// export const socket = io('http://localhost:8080')
// export const socket = io('http://senderserv.com:8080')// 'https://sms-be-k20z.onrender.com'
const socket = io(process.env.REACT_APP_SOKET_URL || 'http://localhost:8080', {
    reconnectionAttempts: 5,
    reconnectionDelay: 2000,
});
//npm rebuild bcrypt --build-from-source


socket.on('connect_error', (error) => {
    console.error('Connection Error:', error);
});

socket.on('reconnect_attempt', (attempt) => {
    console.log(`Reconnect attempt #${attempt}`);
});

socket.on('reconnect_failed', () => {
    console.error('Reconnect failed');
});

export { socket };
