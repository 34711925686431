import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {getDashboard, reloadStatsToDay} from "./dashboard.request";
import {IDashboardStats, IDashboardStatsResponse, IGroupedCardStats, IGroupedTableStats} from "./dashboard.interface";


export type DashboardState = {
    card: IGroupedCardStats[];
    table: IGroupedTableStats[]
    isReload: boolean;
    isLoading: boolean;
};

const initialState: DashboardState = {
    card: [],
    table: [],
    isReload: false,
    isLoading: false,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        statusReload: (state, action) => {
            state.isReload = action.payload.status
        },
        dashboardReload: (state, action) => {
            if(state.isReload) {
                state.card = action.payload.groupedCardStats
                state.table = action.payload.groupedTableStats
            }
        },
        sortTable: (state, action: PayloadAction<{ tableIndex: string; columnKey: string }>) => {
            const { tableIndex, columnKey } = action.payload;
            const targetTable = state.table.filter(t => t.tableIndex === tableIndex)[0];

            if (!targetTable) return;

            const colIndex = targetTable.columns.findIndex(c => c.key === columnKey);

            if (colIndex === -1) return;

            const targetColumn = targetTable.columns[colIndex];

            targetColumn.sort = targetColumn.sort === 'ASC' ? 'DESC' : 'ASC';

            const direction = targetColumn.sort;

            const compareFn = (a: any, b: any) => {
                const valA = a[columnKey];
                const valB = b[columnKey];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return direction === 'ASC' ? (valA - valB) : (valB - valA);
                }

                const strA = String(valA ?? '').toLowerCase();
                const strB = String(valB ?? '').toLowerCase();

                if (strA < strB) return direction === 'ASC' ? -1 : 1;
                if (strA > strB) return direction === 'ASC' ? 1 : -1;
                return 0;
            };

            targetTable.data.sort(compareFn);
        }
    },
    extraReducers: (builder) => {
        builder
            // getDashboard
            .addMatcher(
                (action) => action.type === getDashboard.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getDashboard.fulfilled.type,
                (state, action: PayloadAction<IDashboardStatsResponse>) => {
                    if(action.payload.stats){
                        state.card = action.payload.stats.groupedCardStats
                        state.table = action.payload.stats.groupedTableStats
                    }
                }
            )
            .addMatcher(
                (action) => action.type === getDashboard.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // reloadStatsToDay
            .addMatcher(
                (action) => action.type === reloadStatsToDay.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === reloadStatsToDay.fulfilled.type,
                (state, action: PayloadAction< IDashboardStatsResponse >) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === reloadStatsToDay.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
    },
});

export const { dashboardReload, statusReload, sortTable } = dashboardSlice.actions;

export default dashboardSlice.reducer;
