import {IUser} from "../user/user.interface";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";
import {ChatState} from "./chat.slice";

export const getChat = createAsyncThunk(
    "/chats/get",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.get(`chats/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getChats = createAsyncThunk(
    "/chats/getAll",
    async (
        { showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest }: {
            showTrash: boolean, campaignId: string, showAll: boolean,
            onlyReplied: boolean, onlyUnread: boolean, sortByOldest: boolean
        },
        { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().chat as ChatState
            const response = await Request.get(
                `chats?offset=${0}&limit=${limit}&trash=${showTrash}&all=${showAll}&campaign_id=${campaignId}&replied=${onlyReplied}&unread=${onlyUnread}&oldest=${sortByOldest}`
            )
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const loadMoreChats = createAsyncThunk(
    "/chats/loadmore",
    async ({showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest }: { showTrash: boolean, campaignId: string, showAll: boolean, onlyReplied: boolean, onlyUnread: boolean, sortByOldest: boolean }, { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().chat as ChatState
            const response = await Request.get(
                `chats?offset=${offset}&limit=${limit}&trash=${showTrash}&all=${showAll}&campaign_id=${campaignId}&replied=${onlyReplied}&unread=${onlyUnread}&oldest=${sortByOldest}`
            )
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateChat = createAsyncThunk(
    "/chats/update",
    async ({id, payload}: { id: string, payload: any }, { rejectWithValue }: any) => {
        try {
            const response = await Request.post(`chats/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const autocompleteChats = createAsyncThunk(
    "/chats/autocomplete",
    async (text: string, { rejectWithValue }: any) => {
        try {
            const response = await Request.get(`chats/autocomplete?text=${text}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const sendChatSms = createAsyncThunk(
    "/chats/send",
    async ({id, payload}: { id: string, payload: any }, { rejectWithValue }: any) => {
        try {
            const response = await Request.post(`chats/send/${id}`, payload)
            return response
        } catch (error: any) {
            toast(error, { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const changeSendingNumber = createAsyncThunk(
    "/chats/change/number/",
    async ({id, payload}: { id: string, payload: any }, { rejectWithValue }: any) => {
        try {
            const response = await Request.post(`chats/change/number/${id}`, payload)
            toast('The number has been changed', {type: 'success'})
            return response
        } catch (error: any) {
            toast(error, { type: 'error' })
            return rejectWithValue(error);
        }
    }
)

export const deleteSMSExpired = createAsyncThunk(
    "/chats/sms/expired/",
    async ({id}: { id: string }, { rejectWithValue }: any) => {
        try {
            const response = await Request.delete(`chats/sms/expired/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    })