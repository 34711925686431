import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Box, Button, CircularProgress, Menu, MenuItem, TextField, Typography} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EmojiPicker from 'emoji-picker-react'
import './index.scss'
import moment from "moment";
import { Colors } from "../../../types";
import { useTheme } from "../../../services/theme";
import { useDispatch, useSelector } from "../../../redux/store";
import { v4 as uuidv4 } from 'uuid';
import {InfoModal} from "./utils";
import {changeSendingNumber, deleteSMSExpired, getChat, sendChatSms} from "../../../redux/chat/chat.request";
import {IChat, ISms} from "../../../redux/chat/chat.interface";
import {clearSmsInChat, saveSms} from "../../../redux/chat/chat.slice";
import {clearSms} from "../../../redux/slices/sms";



export const Chat = React.memo(({ chat }: { chat: IChat | null }) => {
    const { isLoadChat, enteredSms } = useSelector(store => store.chat)
    const [sms, setSms] = useState('');
    const { responsesUser } = useSelector(store => store.responses)
    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    const dispatch = useDispatch()

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [anchorEmoji, setAnchorEmoji] = useState<null | HTMLElement>(null)
    const openE = Boolean(anchorEmoji);
    const handleClickE = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEmoji(event.currentTarget);
    };
    const handleCloseE = () => {
        setAnchorEmoji(null);
    };

    const [anchorReplies, setAnchorReplies] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorReplies);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorReplies(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorReplies(null);
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ block: 'end' })
    }

    useEffect(() => {
        const sms = enteredSms.find((sms) => chat?.id === sms.chatId);
        if(sms) setSms(sms.text);
    }, [enteredSms]);

    useEffect(() => {
      scrollToBottom()
    });

    const sendSms = () => {
        if(!sms) return
        if(chat) dispatch(sendChatSms({ id: chat.id, payload: { sms, contact_name: chat.contact_name } }))
        setSms('');
        if(chat) dispatch(clearSmsInChat({chatId: chat.id}))
    }

    const enterSms = (text: string) => {

        setSms(text)
        if(chat) dispatch(saveSms({chatId: chat.id, text}));

    }

    const onChangeSendingNumber = () => {
        setOpenInfoModal(true);
    }

    const onSkipSendingNumber = () => {
        if(chat) dispatch(deleteSMSExpired({id: chat.id})).then(() => {
            dispatch(getChat(chat.id))
        })
    }

    const handleCloseModal = () => {
        setOpenInfoModal(false);
        if(chat) dispatch(changeSendingNumber({id: chat.id, payload: {}})).then(() => {
            dispatch(getChat(chat.id))
        })
    };

    if(isLoadChat || !chat) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><CircularProgress></CircularProgress></Box>

    let formattedNumber = chat.number.length === 10 && !chat.number.startsWith('+')
        ? `+1${chat.number}`
        : chat.number.startsWith('+')
            ? chat.number
            : `+${chat.number}`;

    let formattedSendingNumber = chat.sending_number.length === 10 && !chat.sending_number.startsWith('+')
        ? `+1${chat.sending_number}`
        : chat.sending_number.startsWith('+')
            ? chat.sending_number
            : `+${chat.sending_number}`;

    return (
        <div className="chat">
            {/*{chat.contact_name}*/}
            <div className="chat__title" >
                {formattedNumber.length === 10
                    ? <Typography variant="subtitle2">
                        {formattedNumber.slice(0, 3)} {formattedNumber.slice(3, 6)} {formattedNumber.slice(6, 10)}
                    </Typography>
                    : <Typography variant="subtitle2">
                        {formattedNumber.slice(0, 2)} {formattedNumber.slice(2, 5)} {formattedNumber.slice(5, 8)} {formattedNumber.slice(8, 12)}
                    </Typography>
                }
                {formattedSendingNumber.length === 10
                    ? <Typography variant="caption">Sending:
                        {formattedSendingNumber.slice(0, 3)} {formattedSendingNumber.slice(3, 6)} {formattedSendingNumber.slice(6, 10)}
                    </Typography>
                    : <Typography variant="caption">Sending:
                        {formattedSendingNumber.slice(0, 2)} {formattedSendingNumber.slice(2, 5)} {formattedSendingNumber.slice(5, 8)} {formattedSendingNumber.slice(8, 12)}
                    </Typography>
                }
            </div>
            <div className="chat__messages">

                {
                    chat.messages?.map((sms, n) => <SmsBlock attachment={chat.attachment} onChangeSendingNumber={onChangeSendingNumber} onSkipSendingNumber={onSkipSendingNumber} key={sms.id} isFirst={n === 0} sms={sms} prevSmsTime={chat.messages ? chat.messages[n - 1]?.createdAt : null}></SmsBlock>)
                }

                <div ref={messagesEndRef}></div>
            </div>

            <div className="chat__bottom">

                <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 20 }} onClick={handleClickE}>
                    <img
                        style={{ width: 20, height: 20}}
                        src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f601.png'

                    ></img>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEmoji}
                    open={openE}
                    className='send_menu'
                    onClose={handleCloseE}
                    MenuListProps={{
                    "aria-labelledby": "basic-button",
                    }}
                >
                    <EmojiPicker
                        open={!!anchorEmoji}
                        onEmojiClick={(e) => setSms(prev => prev + e.emoji)}
                    ></EmojiPicker>
                </Menu>

                <div className="summary_messages_bottom_line">
                    <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                            height: '100%',
                            zIndex: 2,
                            padding: 0,
                            minWidth: '30px'
                        }}
                    >
                        <FontAwesomeIcon icon={"fa-solid fa-reply".split(' ') as any}></FontAwesomeIcon>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorReplies}
                        open={open}
                        className='send_menu'
                        onClose={handleClose}
                    >
                       { responsesUser.length ? responsesUser.map(response =>
                            <MenuItem
                                onClick={() => {
                                  setSms(response.text);
                                    handleClose();
                                }}
                            >
                                { response.name }
                            </MenuItem>
                        ) : 'There no quick responses'}
                    </Menu>
                </div>

                <TextField
                    value={sms}
                    onChange={(e) => enterSms(e.target.value)}
                    placeholder="Type your message"
                    fullWidth style={{ marginRight: 4, marginLeft: 4 }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            sendSms();
                        }
                    }}
                ></TextField>


                <Button onClick={sendSms} style={{ minWidth: '40px' }}>
                <FontAwesomeIcon icon={"fa-solid fa-paper-plane".split(' ') as any}></FontAwesomeIcon>
                    {/* Send */}
                </Button>
            </div>

            <InfoModal open={openInfoModal} close={handleCloseModal}></InfoModal>

        </div>

    )
})

const SmsBlock = ({ attachment, sms, prevSmsTime, isFirst, onChangeSendingNumber, onSkipSendingNumber }: { attachment: string | null, sms: ISms, prevSmsTime: string | null, isFirst: boolean, onChangeSendingNumber: () => void, onSkipSendingNumber: () => void }) => {
    const theme = useTheme()
    const endOfDay = moment(sms.createdAt).startOf('day').isAfter(moment(prevSmsTime).startOf('day'))

    const [isImageOpen, setIsImageOpen] = useState(false);

    const openImage = () => {
        setIsImageOpen(true);
    };

    const closeImage = () => {
        setIsImageOpen(false);
    };

    return (
        <>
           {
            (endOfDay || isFirst) &&  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                <span style={{ fontWeight: '200' }}>{moment(sms.createdAt).format('MM/DD dddd')}</span>
            </div>
           }

            <div
                key={sms.id}
                style={{
                    backgroundColor: theme === 'light' ? Colors.MAIN_WHITE : Colors.MAIN_DARK,
                    position: 'relative'
            }}
                className={sms.direction === 'incoming' ? "chat__message" : "chat__message-my"}
            >
                {isFirst && attachment && (
                    <>
                        <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                            alt="Attachment"
                            style={{ width: '30px', height: '30px', borderRadius: 10, cursor: 'pointer', position: 'absolute', top: -15, left: -15 }}
                            onClick={openImage}
                        />

                        {isImageOpen && (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1000,
                                }}
                                onClick={closeImage}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        maxWidth: '90%',
                                        maxHeight: '90%',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                        alt="Full Size Attachment"
                                        style={{ width: '100%', height: 'auto', borderRadius: 10 }}
                                    />
                                    <button
                                        style={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                            background: 'transparent',
                                            border: 'none',
                                            color: '#fff',
                                            fontSize: '24px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={closeImage}
                                    >
                                        <FontAwesomeIcon icon={"fa-xmark" as any} color={"#ff0000"}/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <span>{sms.text}</span>
                <span style={{marginTop: 4, fontSize: 12, alignSelf: 'flex-end'}}>
                    {moment(sms.createdAt).format('h:mm a')}
                    <span style={{marginLeft: "3px"}}>
                        {sms.expired
                            ? <FontAwesomeIcon icon={"fa-exclamation-circle" as any} color={"#ff0000"}/>
                            : (
                                <>
                                    {sms.status === 'sent' && <FontAwesomeIcon icon={"fa-check" as any}/>}
                                    {sms.status === 'delivered' && <FontAwesomeIcon icon={"fa-check-double" as any} color={"#2196f3"}/>}
                                    {!sms.status || (sms.status !== 'sent' && sms.status !== 'delivered') &&
                                        <FontAwesomeIcon icon={"fa-circle" as any} color={"#ff0000"}/>}
                                </>
                            )
                        }
                    </span>
                </span>
            </div>


            {
                sms.expired &&
                (
                    <>
                        <div
                            key={uuidv4()}
                            className={"chat__message-my"}
                            style={{backgroundColor: Colors.MAIN_BLUE, color: Colors.MAIN_WHITE}}
                        >
                            <span><b>The sender's number has been changed, do you want to change the number to a new one?</b></span>
                        </div>
                        <div
                            className={"chat__message-my"}
                            style={{display: 'flex', flexDirection: 'row', padding: 0}}
                        >
                            <Button onClick={onChangeSendingNumber} variant="contained" sx={{marginLeft: '5px', marginRight: '5px'}}>Yes</Button>
                            <Button onClick={onSkipSendingNumber} variant="contained" sx={{marginLeft: '5px', marginRight: '5px'}}>No</Button>
                        </div>
                    </>
                )
           }



        </>


    )
}
