import inMemoryJWT from "../services/inMemoryJWT";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export class Request {
    static url = process.env.REACT_APP_API_URL || 'http://localhost:8080/api/';

    static async request(url: string, options: AxiosRequestConfig = {}) {
        const token = inMemoryJWT.getToken() || '';

        const defaultHeaders: Record<string, string> = {
            "Content-Type": "application/json"
        };

        if (token) {
            defaultHeaders["Authorization"] = `Bearer ${token}`;
        }

        const userHeaders = options.headers || {};
        const { Authorization, ...restDefaultHeaders } = defaultHeaders;

        const finalHeaders = {
            ...restDefaultHeaders,
            ...userHeaders,
            ...(Authorization && { Authorization })
        };

        const config: AxiosRequestConfig = {
            url: `${Request.url}${url}`,
            withCredentials: true,
            ...options,
            headers: finalHeaders,
        };

        try {
            const response: AxiosResponse = await axios(config);
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                return this.handleTokenRefresh(config);
            }

            console.error('Request error', {
                url,
                method: config.method,
                headers: config.headers,
                data: config.data,
                error: error.message,
            });

            throw error.response?.data?.error || error.message;
        }
    }

    static async handleTokenRefresh(config: AxiosRequestConfig) {
        let new_token = null;
        try {
            const refreshResponse: AxiosResponse = await axios.post(`${Request.url}auth/refresh`, {}, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            });
            const data = refreshResponse.data;
            inMemoryJWT.setToken(data.accessToken, data.accessTokenExpiration);
            new_token = data.accessToken;

            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${new_token}`
            };

            const retryResponse: AxiosResponse = await axios(config);
            return retryResponse.data;
        } catch (refreshError: any) {
            inMemoryJWT.setToken(null, null);
            console.error('Token refresh failed:', refreshError.message);
            console.error(refreshError);
        }
    }

    static async get(url: string) {
        return await Request.request(url, { method: 'GET' });
    }

    static async post(url: string, data: any = {}, config: AxiosRequestConfig = {}) {
        return await Request.request(url, {
            method: 'POST',
            data,
            ...config,
        });
    }

    static async delete(url: string) {
        return await Request.request(url, { method: 'DELETE' });
    }
}
