import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, Select, FormControl, MenuItem, SelectChangeEvent, Switch, Chip, OutlinedInput, Autocomplete } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { clearGroups, createGroup, deleteGroup, getGroup, getGroups, updateGroup } from "../../../../redux/slices/groups";
import { getServers } from "../../../../redux/server/server.request";
import {clearServers} from "../../../../redux/server/server.slice";

export const ServerGroups = () => {
    const { group, groups } = useSelector(store => store.groups)
    const { servers } = useSelector(store => store.server)
    const [groupId, setGroupId] = useState<string | null>(null)
    const [openModal, setOpenModal] = useState(false);
    const [name, setName] = useState('')
    const [selectedServers, setSelectedServers] = useState<any[]>([]);
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
      if(groupId) dispatch(getGroup(groupId))
    }, [groupId])

    useEffect(() => {
      if(groupId && group) {
        setName(group.name)
        setSelectedServers(group.servers.map(server => server.id))
      }
    }, [group])

    useEffect(() => {
      dispatch(getGroups())
      dispatch(getServers())

      return () => { dispatch(clearGroups()); dispatch(clearServers()) }
    }, [])

    const onSubmit = () => {
      dispatch(createGroup({ name, selectedServers }))
    }

    const onUpdateGroup = (id: string, payload: any) => {
      dispatch(updateGroup({ id, payload }))
    }

    const onDeleteGroup = (id: string) => {
      dispatch(deleteGroup(id))
    }

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(getGroups())
        }
    }, [horizontalScroll])

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Workspace</Typography><span> /server groups</span>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
               <Box>
                    <Typography variant="h6">Server Groups</Typography>
                    <Typography sx={{ fontSize: 12 }} variant="subtitle2">Manage your server groups here</Typography>
               </Box>
               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => setOpenModal(true)} size="small" variant="contained">Create Server Group</Button>
               </Stack>
            </Container>

            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>TITLE</TableCell>
                        <TableCell>SERVERS</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {groups.map((group) => (
                        <TableRow
                            key={group.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{group.name} </TableCell>
                            <TableCell style={{ display: 'flex', flexDirection: 'column' }}>
                              {group.servers.map(server => <span key={server.id}>{server.name}</span>)}
                            </TableCell>
                            <TableCell>
                              <Switch checked={group.status} onChange={() => onUpdateGroup(group.id, { status: !group.status })}></Switch>
                            </TableCell>
                            <TableCell>
                               <Box sx={{ display: 'flex', gap: 1 }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => onDeleteGroup(group.id)}>
                                        {<FontAwesomeIcon color="red" icon={"fa-solid fa-trash".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => {setGroupId(group.id); setOpenModal(true)}}>
                                        {<FontAwesomeIcon color="skyblue" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                               </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box 
                    sx={{ 
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 24,
                        p: 4, 
                    }}
                >
                    <Typography>Create Server Group</Typography>

                    <TextField value={name} onChange={e => setName(e.target.value)} style={{ marginTop: 30 }} placeholder="Title" fullWidth></TextField>

                    <MultiSelect array={servers} selected={selectedServers} setSelected={setSelectedServers}></MultiSelect>

                    <Box sx={{ marginTop: '20px' }}>
                        <Button onClick={onSubmit} size="large" variant="contained">Save</Button>
                        <Button size="large" style={{ marginLeft: 10 }} variant="outlined">Reset</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

const MultiSelect = ({ array, selected, setSelected }: { array: any[], selected: any[], setSelected: any }) => {
    return (
      <FormControl sx={{ marginTop: 2, width: '100%' }}>
        <InputLabel>Multiple Select</InputLabel>
        <Select
          multiple
          value={selected}
          onChange={(e: any) => setSelected(e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value: any) => (
                <Chip
                  key={value}
                  label={array.find(it => it.id === value)?.name}
                  onDelete={() =>
                    setSelected(
                        selected.filter((item) => item !== value)
                    )
                  }
                  deleteIcon={
                    <div onMouseDown={(event) => event.stopPropagation()}>{<FontAwesomeIcon color="red" icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}</div>
                  }
                />
              ))}
            </Stack>
          )}
        >
          {array.map((item, n) => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item.name}
              {selected.includes(item.id) ? <FontAwesomeIcon color="green" icon={"fa-solid fa-check".split(' ') as any}></FontAwesomeIcon> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};
