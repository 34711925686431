import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";

export const adminService = createAsyncThunk(
    'admin/service',
    async (
        payload: { type: string; status: boolean },
        { rejectWithValue }
    ) => {
        try {
            const response = await Request.post(`/admin/service`, payload);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);