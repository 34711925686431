import { useCallback, useEffect, useState } from "react";
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, TextField} from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { createBlocked, deleteBlocked, getBlocked } from "../../../../redux/blocked/blocked.request";
import moment from "moment";
import {getUsers, updateUser} from "../../../../redux/user/user.request";

export const BlockedNumbers = () => {
    const [number, setNumber] = useState('');
    const [note, setNote] = useState('')
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const { blocked } = useSelector(store => store.blocked)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBlocked())

        // return () => { dispatch(clearUsers()) }
    }, [])

  
    const handleUpdate = (id: string, payload: any) => {
        dispatch(updateUser({ id, payload }))
    }

    const onAddBlocked = useCallback(() => {
        if(!number ) { console.log('err'); return 'err'}
        dispatch(createBlocked({ number, note }))
    }, [number, note])

    const onRemoveBlocked = useCallback((id: string) => {
        dispatch(deleteBlocked(id))
    }, [])

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(getUsers())
        }
    }, [horizontalScroll])

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Workspace</Typography><span> /blocked numbers</span>
            </Box>

            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 4 }}>
               <Box>
                    <Typography variant="h6">Blocked Numbers</Typography>
                    <Typography sx={{ fontSize: 12 }} variant="subtitle2">Manage your blocked numbers here</Typography>
               </Box>
               <Stack direction={{ xs: 'column', sm: 'row' }} marginTop={2} spacing={2}>
                    <TextField value={number} onChange={e => setNumber(e.target.value)} placeholder="Number" fullWidth></TextField>
                    <TextField value={note} onChange={e => setNote(e.target.value)} placeholder="Note" fullWidth></TextField>
                    <Button onClick={onAddBlocked} style={{ minWidth: 100 }} size="small" variant="contained">Add To Block</Button>
               </Stack>
            </Container>


            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>NUMBER</TableCell>
                        <TableCell>NOTE</TableCell>
                        <TableCell>CREATED AT</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {blocked.map((number) => (
                        <TableRow
                            key={number.number}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">{number.number} </TableCell>
                        <TableCell style={{ maxWidth: 400 }}>{number.note}</TableCell>
                        <TableCell>{moment(number.createdAt).format('MM/DD/YYYY h:mm A')}</TableCell>
                        <TableCell>
                            <Button onClick={() => onRemoveBlocked(number.number)} color="error" variant="contained">Remove</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
