import React, { useState } from "react";
import {
    Box,
    IconButton,
    InputAdornment,
    SelectChangeEvent, TextField,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimStatTable} from "./utils";
import {getDashboard, reloadStatsToDay} from "../../../redux/dashboard/dashboard.request";
import { useDispatch, useSelector } from "../../../redux/store";
import {statusReload} from "../../../redux/dashboard/dashboard.slice";
import {toast} from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {CardIQ} from "./components/CardIQ";
import {SIMBoardIQ} from "./components/SIMBoardIQ";
import {getAnalyzeListServer, getAnalyzeReset} from "../../../redux/analyze/analyze.request";
import {fetchListSimWithPagination} from "../../../redux/analyze/analyze.slice";


dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

// type Mode = 'day' | 'week' | 'month';

const DashboardAdmin: React.FC = () => {

    const { listSim, columns, carriers, stats, devices, offset, limit, totalCount  } = useSelector(store => store.analyze);

    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [selectedDevice, setSelectedDevice] = useState<string>("");

    // const { card, table } = useSelector(store => store.dashboard);



    // const [mode, setMode] = useState<Mode>('day');
    // const [date, setDate] = useState<string>('');

    const handleDeviceChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSelectedDevice(value);
        dispatch(getAnalyzeListServer({dev: value}))
    };

    const handleReload = () => {
        // dispatch(reloadStatsToDay())
        // dispatch(statusReload({status: true}))
        dispatch(getAnalyzeListServer({}))
    };

    const handlePageChange = (newOffset: number, newLimit: number) => {
        dispatch(fetchListSimWithPagination({ offset: newOffset, limit: newLimit }));
        dispatch(getAnalyzeListServer({}))
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        // sendSearchRequest(value);
        dispatch(getAnalyzeListServer({phone: value}))
    };

    const handleButtonClick = () => {
        console.log(selectedDevice)
        if(!selectedDevice){
            toast('Please select device', {type: 'error'})
        }
        dispatch(getAnalyzeReset({id: selectedDevice}))
    };

    // const handleModeChange = (event: SelectChangeEvent) => {
    //     const newMode = event.target.value as Mode;
    //     setMode(newMode);
    //     setDate('');
    //     console.log('handleModeChange', newMode);
    // };

    // const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setDate(event.target.value);
    //     console.log('handleDateChange', event.target.value);
    // };

    // const onFilter = () => {
    //     console.log('onFilter', mode, date);
    //     if (date === '') {
    //         toast('Please select date', {type: 'error'})
    //         return;
    //     }
    //
    //     let startDate: Dayjs;
    //     let endDate: Dayjs;
    //
    //     switch (mode) {
    //         case 'day':
    //             startDate = dayjs(date);
    //             endDate = startDate.endOf('day');
    //             break;
    //
    //         case 'week':
    //             const [year, week] = date.split('-W').map(Number);
    //             if (!year || !week || week < 1 || week > 53) {
    //                 toast('Invalid week format. Use YYYY-WWW', { type: 'error' });
    //                 return;
    //             }
    //
    //             startDate = dayjs().year(year).isoWeek(week).startOf('isoWeek');
    //             endDate = dayjs().year(year).isoWeek(week).endOf('isoWeek');
    //             break;
    //
    //         case 'month':
    //             startDate = dayjs(date).startOf('month');
    //             endDate = dayjs(date).endOf('month');
    //             break;
    //
    //         default:
    //             throw new Error('Invalid mode');
    //
    //     }
    //
    //     const startDateString = startDate.toISOString();
    //     const endDateString = endDate.toISOString();
    //
    //     dispatch(getDashboard({startDate: startDateString, endDate: endDateString}))
    //
    // }

    // let dateType: 'date' | 'week' | 'month' = 'date';
    // if (mode === 'week') {
    //     dateType = 'week';
    // } else if (mode === 'month') {
    //     dateType = 'month';
    // }

    return (
        <Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px'
                }}
            >

                {/*<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>*/}
                {/*    <IconButton color="primary" onClick={handleReload}>*/}
                {/*        <FontAwesomeIcon icon={faRotate} />*/}
                {/*    </IconButton>*/}
                {/*</Box>*/}


                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search numbers..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon="search" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleReload}>
                                    <FontAwesomeIcon icon="sync" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: 220 }}
                />

                {/*<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>*/}

                    {/*<FormControl size="small">*/}
                    {/*    <InputLabel id="mode-select-label">Mode</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        labelId="mode-select-label"*/}
                    {/*        id="mode-select"*/}
                    {/*        value={mode}*/}
                    {/*        label="Режим"*/}
                    {/*        onChange={handleModeChange}*/}
                    {/*    >*/}
                    {/*        <MenuItem value="day">Day</MenuItem>*/}
                    {/*        <MenuItem value="week">Week</MenuItem>*/}
                    {/*        <MenuItem value="month">Month</MenuItem>*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}

                    {/*<TextField*/}
                    {/*    size="small"*/}
                    {/*    type={dateType}*/}
                    {/*    label={*/}
                    {/*        mode === 'day' ? 'Day' :*/}
                    {/*            mode === 'week' ? 'Week' :*/}
                    {/*                'Month'*/}
                    {/*    }*/}
                    {/*    InputLabelProps={{ shrink: true }}*/}
                    {/*    value={date}*/}
                    {/*    onChange={handleDateChange}*/}
                    {/*/>*/}

                    {/*<Button onClick={onFilter}>Filter</Button>*/}

                {/*</Box>*/}

            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                {/*{card.map((stat: IGroupedCardStats, index) => (*/}
                {/*    <Box*/}
                {/*        key={index}*/}
                {/*        sx={{*/}
                {/*            flex: 1,*/}
                {/*            marginRight: index !== card.length - 1 ? 2 : 0,*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <CardInfoDashboard*/}
                {/*            title={stat.title || ''}*/}
                {/*            value={stat.value || 0}*/}
                {/*            icon={<FontAwesomeIcon icon={`fa-solid ${stat.icon}`.split(" ") as any}></FontAwesomeIcon>}*/}
                {/*        />*/}
                {/*    </Box>*/}
                {/*))}*/}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        {stats.map((cardProps, index) => (
                            <CardIQ key={index} {...cardProps} />
                        ))}
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                }}>
                    <SIMBoardIQ carriers={carriers}></SIMBoardIQ>
                </Box>

            </Box>

            <Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 1
                    }}
                >
                    <SimStatTable
                        data={listSim}
                        columns={columns}
                        offset={offset}
                        limit={limit}
                        totalCount={totalCount}
                        devices={devices}
                        onPageChange={handlePageChange}
                        handleDeviceChange={handleDeviceChange}
                        selectedDevice={selectedDevice}
                        handleButtonClick={handleButtonClick}
                    />
                </Box>

            </Box>
            {/*<Grid sx={{ marginTop: '20px' }} container spacing={2}>*/}
            {/*    {table.map((cardData: IGroupedTableStats, index) => (*/}
            {/*        <Grid item xs={12} sm={6} md={4} key={index}>*/}
            {/*            <CardListInfoDashboard*/}
            {/*                title={cardData.title}*/}
            {/*                columns={cardData.columns}*/}
            {/*                tableIndex={cardData.tableIndex}*/}
            {/*                data={cardData.data}*/}
            {/*            />*/}
            {/*        </Grid>*/}
            {/*    ))}*/}
            {/*</Grid>*/}

        </Box>
    );
};

export default DashboardAdmin;
