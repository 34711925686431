import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteTracker = () => {
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem('lastVisitedPath', location.pathname);
    }, [location.pathname]);

    return null;
};

export default RouteTracker;
